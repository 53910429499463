define("style-guide/components/style-guide/navigation/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <@Subsection @name="Navigation">
    <Freestyle::Usage @name="Default">
      <:example>
        <Navigation as |navigation|>
          <navigation.Item
            @iconName="home"
            @label="Dashboard"
            @route="protected.dashboard"
          />
          <navigation.Item
            @iconName="assets"
            @label="Assets"
            @route="style-guide"
          />
          <navigation.Item
            @iconName="employees"
            @label="Employees"
            @route="protected"
          />
          <navigation.Item
            @iconName="categories"
            @label="Categories"
            @route="protected"
          />
        </Navigation>
      </:example>
    </Freestyle::Usage>
  </@Subsection>
  */
  {
    "id": "0puvjJv7",
    "block": "[[[8,[30,1],null,[[\"@name\"],[\"Navigation\"]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,0],null,[[\"@name\",\"@source\"],[\"Default\",\"       \\n<Navigation as |navigation|>\\n  <navigation.Item\\n    @iconName=\\\"home\\\"\\n    @label=\\\"Dashboard\\\"\\n    @route=\\\"protected.dashboard\\\"\\n  />\\n  <navigation.Item\\n    @iconName=\\\"assets\\\"\\n    @label=\\\"Assets\\\"\\n    @route=\\\"style-guide\\\"\\n  />\\n  <navigation.Item\\n    @iconName=\\\"employees\\\"\\n    @label=\\\"Employees\\\"\\n    @route=\\\"protected\\\"\\n  />\\n  <navigation.Item\\n    @iconName=\\\"categories\\\"\\n    @label=\\\"Categories\\\"\\n    @route=\\\"protected\\\"\\n  />\\n</Navigation>\\n    \"]],[[\"example\"],[[[[1,\"\\n      \"],[8,[39,1],null,null,[[\"default\"],[[[[1,\"\\n        \"],[8,[30,2,[\"Item\"]],null,[[\"@iconName\",\"@label\",\"@route\"],[\"home\",\"Dashboard\",\"protected.dashboard\"]],null],[1,\"\\n        \"],[8,[30,2,[\"Item\"]],null,[[\"@iconName\",\"@label\",\"@route\"],[\"assets\",\"Assets\",\"style-guide\"]],null],[1,\"\\n        \"],[8,[30,2,[\"Item\"]],null,[[\"@iconName\",\"@label\",\"@route\"],[\"employees\",\"Employees\",\"protected\"]],null],[1,\"\\n        \"],[8,[30,2,[\"Item\"]],null,[[\"@iconName\",\"@label\",\"@route\"],[\"categories\",\"Categories\",\"protected\"]],null],[1,\"\\n      \"]],[2]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]]]]]],[\"@Subsection\",\"navigation\"],false,[\"freestyle/usage\",\"navigation\"]]",
    "moduleName": "style-guide/components/style-guide/navigation/index.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});