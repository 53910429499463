define("style-guide/components/style-guide/theme/box-shadow/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <@Subsection @name="Box Shadow">
    <Freestyle::Usage @name="Box Shadow Default">
      <:example>
        <div class="bg-white shadow">
          Mobility Buddy
        </div>
      </:example>
    </Freestyle::Usage>
  
    <Freestyle::Usage @name="Box Shadow xs">
      <:example>
        <div class="bg-white shadow-xs">
          Mobility Buddy
        </div>
      </:example>
    </Freestyle::Usage>
  
    <Freestyle::Usage @name="Box Shadow sm">
      <:example>
        <div class="bg-white shadow-sm">
          Mobility Buddy
        </div>
      </:example>
    </Freestyle::Usage>
  
    <Freestyle::Usage @name="Box Shadow md">
      <:example>
        <div class="bg-white shadow-md">
          Mobility Buddy
        </div>
      </:example>
    </Freestyle::Usage>
  
    <Freestyle::Usage @name="Box Shadow lg">
      <:example>
        <div class="bg-white shadow-lg">
          Mobility Buddy
        </div>
      </:example>
    </Freestyle::Usage>
  </@Subsection>
  */
  {
    "id": "xt22vrDC",
    "block": "[[[8,[30,1],null,[[\"@name\"],[\"Box Shadow\"]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,0],null,[[\"@name\",\"@source\"],[\"Box Shadow Default\",\"       \\n<div class=\\\"bg-white shadow\\\">\\n  Mobility Buddy\\n</div>\\n    \"]],[[\"example\"],[[[[1,\"\\n      \"],[10,0],[14,0,\"bg-white shadow\"],[12],[1,\"\\n        Mobility Buddy\\n      \"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n  \"],[8,[39,0],null,[[\"@name\",\"@source\"],[\"Box Shadow xs\",\"       \\n<div class=\\\"bg-white shadow-xs\\\">\\n  Mobility Buddy\\n</div>\\n    \"]],[[\"example\"],[[[[1,\"\\n      \"],[10,0],[14,0,\"bg-white shadow-xs\"],[12],[1,\"\\n        Mobility Buddy\\n      \"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n  \"],[8,[39,0],null,[[\"@name\",\"@source\"],[\"Box Shadow sm\",\"       \\n<div class=\\\"bg-white shadow-sm\\\">\\n  Mobility Buddy\\n</div>\\n    \"]],[[\"example\"],[[[[1,\"\\n      \"],[10,0],[14,0,\"bg-white shadow-sm\"],[12],[1,\"\\n        Mobility Buddy\\n      \"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n  \"],[8,[39,0],null,[[\"@name\",\"@source\"],[\"Box Shadow md\",\"       \\n<div class=\\\"bg-white shadow-md\\\">\\n  Mobility Buddy\\n</div>\\n    \"]],[[\"example\"],[[[[1,\"\\n      \"],[10,0],[14,0,\"bg-white shadow-md\"],[12],[1,\"\\n        Mobility Buddy\\n      \"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n  \"],[8,[39,0],null,[[\"@name\",\"@source\"],[\"Box Shadow lg\",\"       \\n<div class=\\\"bg-white shadow-lg\\\">\\n  Mobility Buddy\\n</div>\\n    \"]],[[\"example\"],[[[[1,\"\\n      \"],[10,0],[14,0,\"bg-white shadow-lg\"],[12],[1,\"\\n        Mobility Buddy\\n      \"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]]]]]],[\"@Subsection\"],false,[\"freestyle/usage\"]]",
    "moduleName": "style-guide/components/style-guide/theme/box-shadow/index.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});