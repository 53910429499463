define("ember-simple-auth/initializers/setup-session-restoration", ["exports", "@ember/application", "ember-simple-auth/configuration", "@ember/application/deprecations"], function (_exports, _application, _configuration, _deprecations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = setupSessionRestoration;

  function setupSessionRestoration(registry) {
    if (_configuration.default.useSessionSetupMethod) {
      // return early in case users chose to opt out of initializer behavior.
      return;
    }

    (true && !(false) && (0, _deprecations.deprecate)('Ember Simple Auth: The automatic session initialization is deprecated. Please inject session service in your application route and call the setup method manually.', false, {
      id: 'ember-simple-auth.initializer.setup-session-restoration',
      until: '5.0.0'
    }));
    const ApplicationRoute = registry.resolveRegistration ? registry.resolveRegistration('route:application') : registry.resolve('route:application');
    ApplicationRoute.reopen({
      init() {
        this._super(...arguments);

        const originalBeforeModel = this.beforeModel;

        this.beforeModel = function () {
          if (!this.__usesApplicationRouteMixn__) {
            const sessionService = (0, _application.getOwner)(this).lookup('service:session');

            sessionService._setupHandlers();
          }

          const session = (0, _application.getOwner)(this).lookup('session:main');
          return session.restore().then(() => originalBeforeModel.apply(this, arguments), () => originalBeforeModel.apply(this, arguments));
        };
      }

    });
  }
});