define("ember-freestyle/components/freestyle-variant/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/object"], function (_exports, _component, _templateFactory, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="FreestyleVariant {{if this.inline 'FreestyleVariant--inline'}}">
    {{#if this.show}}
      {{yield}}
    {{/if}}
  </div>
  */
  {
    "id": "VPmJuT59",
    "block": "[[[10,0],[15,0,[29,[\"FreestyleVariant \",[52,[30,0,[\"inline\"]],\"FreestyleVariant--inline\"]]]],[12],[1,\"\\n\"],[41,[30,0,[\"show\"]],[[[1,\"    \"],[18,1,null],[1,\"\\n\"]],[]],null],[13]],[\"&default\"],false,[\"if\",\"yield\"]]",
    "moduleName": "ember-freestyle/components/freestyle-variant/index.hbs",
    "isStrictMode": false
  });
  /* eslint-disable ember/no-component-lifecycle-hooks */


  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend({
    tagName: '',
    inline: (0, _object.computed)('collection.{inline,activeKey}', function () {
      let activeKey = this.collection.activeKey;
      return this.collection.inline && activeKey === 'all';
    }),
    show: (0, _object.computed)('collection.activeKey', 'key', function () {
      let activeKey = this.collection.activeKey;
      return activeKey === 'all' || activeKey === this.key;
    }),

    willRender() {
      this._super(...arguments);

      this.collection.registerVariant(this.key);
    }

  }));

  _exports.default = _default;
});