define("ember-freestyle/components/freestyle/usage/object/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Freestyle::Usage::Argument
    @type="Object"
    @name={{@name}}
    @description={{@description}}
    @required={{@required}}
    @defaultValue={{@defaultValue}}
  >
    <Freestyle::Usage::Object::Control
      @value={{@value}}
      @jsonCollapseDepth={{@jsonCollapseDepth}}
    />
  </Freestyle::Usage::Argument>
  */
  {
    "id": "pxJvgBIf",
    "block": "[[[8,[39,0],null,[[\"@type\",\"@name\",\"@description\",\"@required\",\"@defaultValue\"],[\"Object\",[30,1],[30,2],[30,3],[30,4]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,1],null,[[\"@value\",\"@jsonCollapseDepth\"],[[30,5],[30,6]]],null],[1,\"\\n\"]],[]]]]]],[\"@name\",\"@description\",\"@required\",\"@defaultValue\",\"@value\",\"@jsonCollapseDepth\"],false,[\"freestyle/usage/argument\",\"freestyle/usage/object/control\"]]",
    "moduleName": "ember-freestyle/components/freestyle/usage/object/index.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});