define("ember-link/link", ["exports", "@ember/application", "@ember/debug", "@ember/object", "@glimmer/tracking"], function (_exports, _application, _debug, _object, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.UILink = void 0;
  _exports.isQueryParams = isQueryParams;

  var _class, _descriptor, _class3, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const MAIN_BUTTON = 0;

  function isQueryParams(maybeQueryParam) {
    return maybeQueryParam?.isQueryParams && typeof maybeQueryParam.values === 'object';
  } // eslint-disable-next-line @typescript-eslint/ban-types


  function freezeParams(params) {
    if (true
    /* DEBUG */
    ) {
      if (params.models) Object.freeze(params.models);
      if (params.query) Object.freeze(params.query);
      return Object.freeze(params);
    }

    return params;
  }

  function isUnmodifiedLeftClick(event) {
    return event.button === MAIN_BUTTON && !event.ctrlKey && !event.metaKey;
  }

  function isMouseEvent(event) {
    return typeof event === 'object' && event !== null && 'button' in event;
  }

  let Link = (_class3 = class Link {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    constructor(linkManager, params) {
      _initializerDefineProperty(this, "_params", _descriptor2, this);

      _defineProperty(this, "_linkManager", void 0);

      (0, _application.setOwner)(this, (0, _application.getOwner)(linkManager));
      this._linkManager = linkManager;
      this._params = freezeParams(params);
    }

    get _routeArgs() {
      const {
        routeName,
        models,
        queryParams
      } = this;

      if (queryParams) {
        return [routeName, ...models, // Cloning `queryParams` is necessary, since we freeze it, but Ember
        // wants to mutate it.
        {
          queryParams: { ...queryParams
          }
        }];
      }

      return [routeName, ...models];
    }
    /**
     * Whether this route is currently active, including potentially supplied
     * models and query params.
     */


    get isActive() {
      if (!this._linkManager.isRouterInitialized) return false;
      this._linkManager.currentTransitionStack; // eslint-disable-line @typescript-eslint/no-unused-expressions

      return this._linkManager.router.isActive(...this._routeArgs);
    }
    /**
     * Whether this route is currently active, including potentially supplied
     * models, but ignoring query params.
     */


    get isActiveWithoutQueryParams() {
      if (!this._linkManager.isRouterInitialized) return false;
      this._linkManager.currentTransitionStack; // eslint-disable-line @typescript-eslint/no-unused-expressions

      return this._linkManager.router.isActive(this.routeName, // Unfortunately TypeScript is not clever enough to support "rest"
      // parameters in the middle.
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ...this.models);
    }
    /**
     * Whether this route is currently active, but ignoring models and query
     * params.
     */


    get isActiveWithoutModels() {
      if (!this._linkManager.isRouterInitialized) return false;
      this._linkManager.currentTransitionStack; // eslint-disable-line @typescript-eslint/no-unused-expressions

      return this._linkManager.router.isActive(this.routeName);
    }
    /**
     * Whether this route is currently being transitioned into / entered.
     */


    get isEntering() {
      return this._isTransitioning('to');
    }
    /**
     * Whether this route is currently being transitioned out of / exited.
     */


    get isExiting() {
      return this._isTransitioning('from');
    }
    /**
     * The URL for this link that you can pass to an `<a>` tag as the `href`
     * attribute.
     */


    get url() {
      if (!this._linkManager.isRouterInitialized) return '';
      return this._linkManager.router.urlFor(...this._routeArgs);
    }
    /**
     * Deprecated alias for `url`.
     */


    get href() {
      (true && !(false) && (0, _debug.deprecate)('`href` is deprecated. Use `url` instead.', false, {
        id: 'ember-link.link.href',
        until: '2.0.0'
      }));
      return this.url;
    }
    /**
     * Alias for `url`.
     *
     * Allows for more ergonomic composition as query parameters.
     *
     * ```hbs
     * {{link "foo" query=(hash bar=(link "bar"))}}
     * ```
     */


    toString() {
      return this.url;
    }
    /**
     * The `RouteInfo` object for the target route.
     */
    // get route(): RouteInfo {
    //   return this._linkManager.router.recognize(this.url);
    // }

    /**
     * The target route name of this link.
     */


    get routeName() {
      return this._params.route;
    }
    /**
     * The fully qualified target route name of this link.
     */


    get qualifiedRouteName() {
      // Ignore `Property 'recognize' does not exist on type 'RouterService'.`
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const routeInfo = this._linkManager.router.recognize(this.url);

      return routeInfo.name;
    }
    /**
     * The route models passed in this link.
     */


    get models() {
      return this._params.models ?? [];
    }
    /**
     * The query params for this link, if specified.
     */


    get queryParams() {
      return this._params.query;
    }

    _isTransitioning(direction) {
      return this._linkManager.currentTransitionStack?.some(transition => {
        return transition[direction]?.name === this.qualifiedRouteName;
      }) ?? false;
    }
    /**
     * Transition into the target route.
     */


    transitionTo() {
      (true && !(this._linkManager.isRouterInitialized) && (0, _debug.assert)('You can only call `transitionTo`, when the router is initialized, e.g. when using `setupApplicationTest`.', this._linkManager.isRouterInitialized));
      return this._linkManager.router.transitionTo(...this._routeArgs);
    }
    /**
     * Transition into the target route while replacing the current URL, if
     * possible.
     */


    replaceWith() {
      (true && !(this._linkManager.isRouterInitialized) && (0, _debug.assert)('You can only call `replaceWith`, when the router is initialized, e.g. when using `setupApplicationTest`.', this._linkManager.isRouterInitialized));
      return this._linkManager.router.replaceWith(...this._routeArgs);
    }

  }, (_descriptor2 = _applyDecoratedDescriptor(_class3.prototype, "_params", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class3.prototype, "transitionTo", [_object.action], Object.getOwnPropertyDescriptor(_class3.prototype, "transitionTo"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "replaceWith", [_object.action], Object.getOwnPropertyDescriptor(_class3.prototype, "replaceWith"), _class3.prototype)), _class3);
  _exports.default = Link;
  let UILink = (_class = class UILink extends Link {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "_params", _descriptor, this);
    }

    preventDefault(event) {
      if ((this._params.preventDefault ?? true) && typeof event?.preventDefault === 'function') {
        event.preventDefault();
      }
    }
    /**
     * Transition into the target route.
     *
     * Optionally call `preventDefault()`, if an `Event` is passed in.
     */


    transitionTo(event) {
      if (isMouseEvent(event) && !isUnmodifiedLeftClick(event)) return; // Intentionally putting this *before* the assertion to prevent navigating
      // away in case of a failed assertion.

      this.preventDefault(event);
      return super.transitionTo();
    }
    /**
     * Transition into the target route while replacing the current URL, if
     * possible.
     *
     * Optionally call `preventDefault()`, if an `Event` is passed in.
     */


    replaceWith(event) {
      if (isMouseEvent(event) && !isUnmodifiedLeftClick(event)) return; // Intentionally putting this *before* the assertion to prevent navigating
      // away in case of a failed assertion.

      this.preventDefault(event);
      return super.replaceWith();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "_params", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "transitionTo", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "transitionTo"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "replaceWith", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "replaceWith"), _class.prototype)), _class);
  _exports.UILink = UILink;
});