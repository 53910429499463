define("ember-freestyle/components/freestyle-dynamic/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/polyfills", "@ember/object", "@ember/debug"], function (_exports, _component, _templateFactory, _polyfills, _object, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="FreestyleDynamic">
  
    <FreestyleAnnotation>
      <h3 class="FreestyleDynamic-header">{{this.headerTitle}}</h3>
      <form class="FreestyleDynamic-form">
        {{#each-in @dynamicProperties as |propertyName propertyHash|}}
          <FreestyleDynamicInput
            @propertyName={{propertyName}}
            @value={{propertyHash.value}}
            @options={{propertyHash.options}}
            @inputType={{propertyHash.inputType}}
            @description={{propertyHash.description}}
            @min={{propertyHash.min}}
            @max={{propertyHash.max}}
            @changeValueTo={{fn this.changeValueTo propertyName}} />
        {{/each-in}}
      </form>
    </FreestyleAnnotation>
  
    <FreestyleUsage
      @slug={{@slug}}
      @title={{@title}}
      @isDynamic={{true}}
      @componentName={{@componentName}}
      @source={{@source}}
      @dynamicProperties={{@dynamicProperties}}
    >
      {{yield this.dynamicPropertyValues}}
    </FreestyleUsage>
  
  </div>
  
  */
  {
    "id": "OIDtenpA",
    "block": "[[[10,0],[14,0,\"FreestyleDynamic\"],[12],[1,\"\\n\\n  \"],[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n    \"],[10,\"h3\"],[14,0,\"FreestyleDynamic-header\"],[12],[1,[30,0,[\"headerTitle\"]]],[13],[1,\"\\n    \"],[10,\"form\"],[14,0,\"FreestyleDynamic-form\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[30,1]],null],null,[[[1,\"        \"],[8,[39,3],null,[[\"@propertyName\",\"@value\",\"@options\",\"@inputType\",\"@description\",\"@min\",\"@max\",\"@changeValueTo\"],[[30,3],[30,2,[\"value\"]],[30,2,[\"options\"]],[30,2,[\"inputType\"]],[30,2,[\"description\"]],[30,2,[\"min\"]],[30,2,[\"max\"]],[28,[37,4],[[30,0,[\"changeValueTo\"]],[30,3]],null]]],null],[1,\"\\n\"]],[2,3]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\\n  \"],[8,[39,5],null,[[\"@slug\",\"@title\",\"@isDynamic\",\"@componentName\",\"@source\",\"@dynamicProperties\"],[[30,4],[30,5],true,[30,6],[30,7],[30,1]]],[[\"default\"],[[[[1,\"\\n    \"],[18,8,[[30,0,[\"dynamicPropertyValues\"]]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\\n\"],[13],[1,\"\\n\"]],[\"@dynamicProperties\",\"propertyHash\",\"propertyName\",\"@slug\",\"@title\",\"@componentName\",\"@source\",\"&default\"],false,[\"freestyle-annotation\",\"each\",\"-each-in\",\"freestyle-dynamic-input\",\"fn\",\"freestyle-usage\",\"yield\"]]",
    "moduleName": "ember-freestyle/components/freestyle-dynamic/index.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend((_obj = {
    tagName: '',
    headerTitle: 'Dynamic Properties:',
    // Need this separate property for freestyle-dynamic's dynamic.<property> to work
    dynamicPropertyValues: (0, _object.computed)('dynamicProperties', function () {
      let dynamicPropertyValues = {};
      const dynamicProperties = this.dynamicProperties;
      Object.keys(dynamicProperties).forEach(propertyName => {
        dynamicPropertyValues[propertyName] = (0, _object.get)(dynamicProperties, `${propertyName}.value`);
      });
      return dynamicPropertyValues;
    }),

    init() {
      this._super(...arguments);

      let dynamicProperties = this.dynamicProperties;

      if (dynamicProperties) {
        (true && !(typeof dynamicProperties === 'object') && (0, _debug.assert)(`dynamicProperties passed into freestyle-dynamic must be an object.  You passed: ${dynamicProperties}`, typeof dynamicProperties === 'object'));
      } else {
        (0, _object.set)(this, 'dynamicProperties', {});
      }
    },

    changeValueTo(property, newValue) {
      // Make a copy and then reset dynamicProperties, to force template changes
      let dynamicProperties = (0, _polyfills.assign)({}, this.dynamicProperties);
      (0, _object.set)(dynamicProperties, `${property}.value`, newValue);
      this.set('dynamicProperties', dynamicProperties);
    }

  }, (_applyDecoratedDescriptor(_obj, "changeValueTo", [_object.action], Object.getOwnPropertyDescriptor(_obj, "changeValueTo"), _obj)), _obj)).reopenClass({
    positionalParams: ['slug']
  }));

  _exports.default = _default;
});