define("ember-freestyle/components/freestyle-annotation/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/service", "@ember/object"], function (_exports, _component, _templateFactory, _service, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="FreestyleAnnotation {{if this.showNotes 'is-showing' 'is-hidden'}}">
    {{#if this.showNotes}}
      {{yield}}
    {{/if}}
  </div>
  
  */
  {
    "id": "9CNGNNgK",
    "block": "[[[10,0],[15,0,[29,[\"FreestyleAnnotation \",[52,[30,0,[\"showNotes\"]],\"is-showing\",\"is-hidden\"]]]],[12],[1,\"\\n\"],[41,[30,0,[\"showNotes\"]],[[[1,\"    \"],[18,1,null],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"]],[\"&default\"],false,[\"if\",\"yield\"]]",
    "moduleName": "ember-freestyle/components/freestyle-annotation/index.hbs",
    "isStrictMode": false
  });

  let FreestyleAnnotation = _component.default.extend({
    tagName: '',
    emberFreestyle: (0, _service.inject)(),
    showNotes: (0, _object.computed)('emberFreestyle.{showNotes,focus}', 'slug', function () {
      let slug = this.slug;
      let focus = this.emberFreestyle.focus;

      if (focus && slug) {
        return slug.match(focus);
      }

      return this.emberFreestyle.showNotes;
    })
  });

  FreestyleAnnotation.reopenClass({
    positionalParams: ['slug']
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, FreestyleAnnotation);

  _exports.default = _default;
});