define("style-guide/components/style-guide/input-field/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <@Subsection @name="Input Fields">
    <FreestyleCollection as |collection|>
      <collection.variant @key="default">
        <Freestyle::Usage @name="Placeholder">
          <:example>
            <InputField placeholder="Placeholder" />
          </:example>
        </Freestyle::Usage>
        <Freestyle::Usage @name="Filled">
          <:example>
            <InputField value="A value" />
          </:example>
        </Freestyle::Usage>
        <Freestyle::Usage @name="Error">
          <:example>
            <InputField @hasError={{true}} value="Value with error" />
          </:example>
        </Freestyle::Usage>
        <Freestyle::Usage @name="Disabled">
          <:example>
            <InputField placeholder="Disabled field" disabled />
          </:example>
        </Freestyle::Usage>
      </collection.variant>
    </FreestyleCollection>
  </@Subsection>
  */
  {
    "id": "gkT67i+y",
    "block": "[[[8,[30,1],null,[[\"@name\"],[\"Input Fields\"]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[30,2,[\"variant\"]],null,[[\"@key\"],[\"default\"]],[[\"default\"],[[[[1,\"\\n      \"],[8,[39,1],null,[[\"@name\",\"@source\"],[\"Placeholder\",\"       \\n<InputField placeholder=\\\"Placeholder\\\" />\\n        \"]],[[\"example\"],[[[[1,\"\\n          \"],[8,[39,2],[[24,\"placeholder\",\"Placeholder\"]],null,null],[1,\"\\n        \"]],[]]]]],[1,\"\\n      \"],[8,[39,1],null,[[\"@name\",\"@source\"],[\"Filled\",\"       \\n<InputField value=\\\"A value\\\" />\\n        \"]],[[\"example\"],[[[[1,\"\\n          \"],[8,[39,2],[[24,2,\"A value\"]],null,null],[1,\"\\n        \"]],[]]]]],[1,\"\\n      \"],[8,[39,1],null,[[\"@name\",\"@source\"],[\"Error\",\"       \\n<InputField @hasError={{true}} value=\\\"Value with error\\\" />\\n        \"]],[[\"example\"],[[[[1,\"\\n          \"],[8,[39,2],[[24,2,\"Value with error\"]],[[\"@hasError\"],[true]],null],[1,\"\\n        \"]],[]]]]],[1,\"\\n      \"],[8,[39,1],null,[[\"@name\",\"@source\"],[\"Disabled\",\"       \\n<InputField placeholder=\\\"Disabled field\\\" disabled />\\n        \"]],[[\"example\"],[[[[1,\"\\n          \"],[8,[39,2],[[24,\"placeholder\",\"Disabled field\"],[24,\"disabled\",\"\"]],null,null],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[2]]]]],[1,\"\\n\"]],[]]]]]],[\"@Subsection\",\"collection\"],false,[\"freestyle-collection\",\"freestyle/usage\",\"input-field\"]]",
    "moduleName": "style-guide/components/style-guide/input-field/index.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});