define("style-guide/components/style-guide/tabs/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <@Subsection @name="Tabs">
    <Freestyle::Usage @name="Tabs">
      <:example>
        <Tabs as |tabs|>
          <tabs.Item
            @route="style-guide"
          >
            Tab 1
          </tabs.Item>
          <tabs.Item
            @query={{hash
              s="Components"
              ss="Tabs"
            }}
            @route="style-guide"
          >
            Tab 2
          </tabs.Item>
          <tabs.Item
            @query={{hash
              s="Components"
              ss="Tabs"
            }}
            @route="style-guide"
          >
            Tab 3
          </tabs.Item>
          <tabs.Item
            @query={{hash
              s="Components"
              ss="Tabs"
            }}
            @route="style-guide"
          >
            Tab 4
          </tabs.Item>
        </Tabs>
      </:example>
    </Freestyle::Usage>
  </@Subsection>
  */
  {
    "id": "QUrBwdjn",
    "block": "[[[8,[30,1],null,[[\"@name\"],[\"Tabs\"]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,0],null,[[\"@name\",\"@source\"],[\"Tabs\",\"       \\n<Tabs as |tabs|>\\n  <tabs.Item\\n    @route=\\\"style-guide\\\"\\n  >\\n    Tab 1\\n  </tabs.Item>\\n  <tabs.Item\\n    @query={{hash\\n      s=\\\"Components\\\"\\n      ss=\\\"Tabs\\\"\\n    }}\\n    @route=\\\"style-guide\\\"\\n  >\\n    Tab 2\\n  </tabs.Item>\\n  <tabs.Item\\n    @query={{hash\\n      s=\\\"Components\\\"\\n      ss=\\\"Tabs\\\"\\n    }}\\n    @route=\\\"style-guide\\\"\\n  >\\n    Tab 3\\n  </tabs.Item>\\n  <tabs.Item\\n    @query={{hash\\n      s=\\\"Components\\\"\\n      ss=\\\"Tabs\\\"\\n    }}\\n    @route=\\\"style-guide\\\"\\n  >\\n    Tab 4\\n  </tabs.Item>\\n</Tabs>\\n    \"]],[[\"example\"],[[[[1,\"\\n      \"],[8,[39,1],null,null,[[\"default\"],[[[[1,\"\\n        \"],[8,[30,2,[\"Item\"]],null,[[\"@route\"],[\"style-guide\"]],[[\"default\"],[[[[1,\"\\n          Tab 1\\n        \"]],[]]]]],[1,\"\\n        \"],[8,[30,2,[\"Item\"]],null,[[\"@query\",\"@route\"],[[28,[37,2],null,[[\"s\",\"ss\"],[\"Components\",\"Tabs\"]]],\"style-guide\"]],[[\"default\"],[[[[1,\"\\n          Tab 2\\n        \"]],[]]]]],[1,\"\\n        \"],[8,[30,2,[\"Item\"]],null,[[\"@query\",\"@route\"],[[28,[37,2],null,[[\"s\",\"ss\"],[\"Components\",\"Tabs\"]]],\"style-guide\"]],[[\"default\"],[[[[1,\"\\n          Tab 3\\n        \"]],[]]]]],[1,\"\\n        \"],[8,[30,2,[\"Item\"]],null,[[\"@query\",\"@route\"],[[28,[37,2],null,[[\"s\",\"ss\"],[\"Components\",\"Tabs\"]]],\"style-guide\"]],[[\"default\"],[[[[1,\"\\n          Tab 4\\n        \"]],[]]]]],[1,\"\\n      \"]],[2]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]]]]]],[\"@Subsection\",\"tabs\"],false,[\"freestyle/usage\",\"tabs\",\"hash\"]]",
    "moduleName": "style-guide/components/style-guide/tabs/index.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});