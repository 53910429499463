define("style-guide/components/style-guide/panel/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <@Subsection @name="Panel">
    <FreestyleCollection as |collection|>
      <collection.variant @key="default">
        <Freestyle::Usage @name="Primary Default">
          <:example>
            <Panel as |panel|>
              <panel.Section>
                Some content in panel
              </panel.Section>
            </Panel>
          </:example>
        </Freestyle::Usage>
      </collection.variant>
      <collection.variant @key="sections">
        <Freestyle::Usage @name="With sections">
          <:example>
            <Panel as |panel|>
              <panel.Section>
                Section 1 content
              </panel.Section>
              <panel.Section>
                Section 2 content
              </panel.Section>
            </Panel>
          </:example>
        </Freestyle::Usage>
      </collection.variant>
    </FreestyleCollection>
  </@Subsection>
  */
  {
    "id": "i1FuQLIp",
    "block": "[[[8,[30,1],null,[[\"@name\"],[\"Panel\"]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[30,2,[\"variant\"]],null,[[\"@key\"],[\"default\"]],[[\"default\"],[[[[1,\"\\n      \"],[8,[39,1],null,[[\"@name\",\"@source\"],[\"Primary Default\",\"       \\n<Panel as |panel|>\\n  <panel.Section>\\n    Some content in panel\\n  </panel.Section>\\n</Panel>\\n        \"]],[[\"example\"],[[[[1,\"\\n          \"],[8,[39,2],null,null,[[\"default\"],[[[[1,\"\\n            \"],[8,[30,3,[\"Section\"]],null,null,[[\"default\"],[[[[1,\"\\n              Some content in panel\\n            \"]],[]]]]],[1,\"\\n          \"]],[3]]]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n    \"],[8,[30,2,[\"variant\"]],null,[[\"@key\"],[\"sections\"]],[[\"default\"],[[[[1,\"\\n      \"],[8,[39,1],null,[[\"@name\",\"@source\"],[\"With sections\",\"       \\n<Panel as |panel|>\\n  <panel.Section>\\n    Section 1 content\\n  </panel.Section>\\n  <panel.Section>\\n    Section 2 content\\n  </panel.Section>\\n</Panel>\\n        \"]],[[\"example\"],[[[[1,\"\\n          \"],[8,[39,2],null,null,[[\"default\"],[[[[1,\"\\n            \"],[8,[30,4,[\"Section\"]],null,null,[[\"default\"],[[[[1,\"\\n              Section 1 content\\n            \"]],[]]]]],[1,\"\\n            \"],[8,[30,4,[\"Section\"]],null,null,[[\"default\"],[[[[1,\"\\n              Section 2 content\\n            \"]],[]]]]],[1,\"\\n          \"]],[4]]]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[2]]]]],[1,\"\\n\"]],[]]]]]],[\"@Subsection\",\"collection\",\"panel\",\"panel\"],false,[\"freestyle-collection\",\"freestyle/usage\",\"panel\"]]",
    "moduleName": "style-guide/components/style-guide/panel/index.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});