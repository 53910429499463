define("ember-freestyle/components/freestyle-dynamic-input-select-option/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/object"], function (_exports, _component, _templateFactory, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <option value={{@optionValue}} selected={{this.selected}}>
    {{@optionValue}}
  </option>
  
  */
  {
    "id": "L7kzRuiB",
    "block": "[[[10,\"option\"],[15,2,[30,1]],[15,\"selected\",[30,0,[\"selected\"]]],[12],[1,\"\\n  \"],[1,[30,1]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@optionValue\"],false,[]]",
    "moduleName": "ember-freestyle/components/freestyle-dynamic-input-select-option/index.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend({
    tagName: '',
    selected: (0, _object.computed)('optionValue', 'selectedValue', function () {
      return this.optionValue === this.selectedValue;
    })
  }));

  _exports.default = _default;
});