define("@bagaar/ember-pagination/templates/components/pagination-data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IoL0yypO",
    "block": "[[[18,1,[[28,[37,1],null,[[\"previousPage\",\"currentPage\",\"nextPage\",\"lastPage\",\"isFirstPage\",\"isLastPage\",\"itemsPerPage\",\"activeItems\",\"totalPages\",\"allPages\",\"pageRangePages\",\"startMarginPages\",\"endMarginPages\",\"shouldShowLowerBreak\",\"shouldShowUpperBreak\"],[[30,0,[\"previousPage\"]],[30,0,[\"_currentPage\"]],[30,0,[\"nextPage\"]],[30,0,[\"lastPage\"]],[30,0,[\"isFirstPage\"]],[30,0,[\"isLastPage\"]],[30,0,[\"itemsPerPage\"]],[30,0,[\"activeItems\"]],[30,0,[\"totalPages\"]],[30,0,[\"allPages\"]],[30,0,[\"pageRangePages\"]],[30,0,[\"startMarginPages\"]],[30,0,[\"endMarginPages\"]],[30,0,[\"shouldShowLowerBreak\"]],[30,0,[\"shouldShowUpperBreak\"]]]]]]]],[\"&default\"],false,[\"yield\",\"hash\"]]",
    "moduleName": "@bagaar/ember-pagination/templates/components/pagination-data.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});