define("ember-freestyle/services/ember-freestyle", ["exports", "@ember/service", "@ember/utils", "@ember/array", "rsvp", "@glimmer/tracking", "@ember/object"], function (_exports, _service, _utils, _array, _rsvp, _tracking, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let EmberFreestyleService = (_class = class EmberFreestyleService extends _service.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "showLabels", _descriptor, this);

      _initializerDefineProperty(this, "showNotes", _descriptor2, this);

      _initializerDefineProperty(this, "showCode", _descriptor3, this);

      _initializerDefineProperty(this, "showApi", _descriptor4, this);

      _initializerDefineProperty(this, "menu", _descriptor5, this);

      _initializerDefineProperty(this, "showMenu", _descriptor6, this);

      _initializerDefineProperty(this, "allowRenderingAllSections", _descriptor7, this);

      _defineProperty(this, "defaultTheme", 'zenburn');

      _initializerDefineProperty(this, "section", _descriptor8, this);

      _initializerDefineProperty(this, "subsection", _descriptor9, this);

      _initializerDefineProperty(this, "focus", _descriptor10, this);

      _defineProperty(this, "hljsVersion", '9.12.0');

      _defineProperty(this, "hljsPromise", null);

      _defineProperty(this, "hljsLanguagePromises", {});
    }

    get notFocused() {
      return !this.focus;
    }

    shouldShowSection(sectionName) {
      let focusedSection = this.section;

      if ((0, _utils.isBlank)(focusedSection) && this.allowRenderingAllSections) {
        return true;
      }

      return sectionName === focusedSection;
    }

    shouldShowSubsection(sectionName, subsectionName) {
      if (!this.shouldShowSection(sectionName)) {
        return false;
      }

      let focusedSubsection = this.subsection;
      return (0, _utils.isBlank)(focusedSubsection) || subsectionName === focusedSubsection;
    }

    hljsUrl() {
      return `https://cdnjs.cloudflare.com/ajax/libs/highlight.js/${this.hljsVersion}/highlight.min.js`;
    }

    hljsThemeUrl(theme) {
      return `https://cdnjs.cloudflare.com/ajax/libs/highlight.js/${this.hljsVersion}/styles/${theme}.min.css`;
    }

    hljsLanguageUrl(language) {
      return `https://cdnjs.cloudflare.com/ajax/libs/highlight.js/${this.hljsVersion}/languages/${language}.min.js`;
    }

    ensureHljs() {
      if (!this.hljsPromise) {
        this.hljsPromise = new _rsvp.Promise(resolve => {
          let src = this.hljsUrl();
          let script = document.createElement('script');
          script.type = 'application/javascript';
          script.src = src;
          script.onload = resolve;
          document.body.appendChild(script);
        });
      }

      return this.hljsPromise;
    }

    highlight(el) {
      this.ensureHljs().then(() => {
        return this.ensureHljsLanguage('handlebars');
      }).then(() => {
        hljs.highlightBlock(el);
      });
    }

    ensureHljsTheme(theme) {
      if (document.querySelector(`[data-hljs-theme=${theme}]`)) {
        return;
      }

      let link = document.createElement('link');
      link.rel = 'stylesheet';
      link.type = 'text/css';
      link.href = this.hljsThemeUrl(theme);
      link.setAttribute('data-hljs-theme', `${theme}`);
      document.head.appendChild(link);
    }

    ensureHljsLanguage(language) {
      if (!this.hljsLanguagePromises[language]) {
        this.hljsLanguagePromises[language] = new _rsvp.Promise(resolve => {
          let src = this.hljsLanguageUrl(language);
          let script = document.createElement('script');
          script.type = 'application/javascript';
          script.src = src;
          script.onload = resolve;
          document.body.appendChild(script);
        });
      }

      return this.hljsLanguagePromises[language];
    } // menu - tree structure of named sections with named subsections


    registerSection(sectionName) {
      let subsectionName = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
      let menu = this.menu || (0, _array.A)([]);

      if (!menu.filterBy('name', sectionName).length) {
        menu.push({
          name: sectionName,
          subsections: (0, _array.A)([])
        });
      }

      if ((0, _utils.isPresent)(subsectionName)) {
        let section = menu.findBy('name', sectionName);

        if (!section.subsections.filterBy('name', subsectionName).length) {
          section.subsections.push({
            name: subsectionName
          });
        }
      }

      this.menu = menu;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "showLabels", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "showNotes", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "showCode", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "showApi", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "menu", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "showMenu", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "allowRenderingAllSections", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "section", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "subsection", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "focus", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "registerSection", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "registerSection"), _class.prototype)), _class);
  _exports.default = EmberFreestyleService;
});