define("style-guide/components/style-guide/stacked-list/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <@Subsection @name="Stacked List">
    <Freestyle::Usage @name="Stacked List">
      <:example>
        <StackedList as |Item|>
          <Item>
            First
          </Item>
          <Item>
            Second
          </Item>
          <Item>
            Third
          </Item>
        </StackedList>
      </:example>
    </Freestyle::Usage>
  </@Subsection>
  
  <ModalContainer />
  */
  {
    "id": "UemPBe4+",
    "block": "[[[8,[30,1],null,[[\"@name\"],[\"Stacked List\"]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,0],null,[[\"@name\",\"@source\"],[\"Stacked List\",\"       \\n<StackedList as |Item|>\\n  <Item>\\n    First\\n  </Item>\\n  <Item>\\n    Second\\n  </Item>\\n  <Item>\\n    Third\\n  </Item>\\n</StackedList>\\n    \"]],[[\"example\"],[[[[1,\"\\n      \"],[8,[39,1],null,null,[[\"default\"],[[[[1,\"\\n        \"],[8,[30,2],null,null,[[\"default\"],[[[[1,\"\\n          First\\n        \"]],[]]]]],[1,\"\\n        \"],[8,[30,2],null,null,[[\"default\"],[[[[1,\"\\n          Second\\n        \"]],[]]]]],[1,\"\\n        \"],[8,[30,2],null,null,[[\"default\"],[[[[1,\"\\n          Third\\n        \"]],[]]]]],[1,\"\\n      \"]],[2]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\\n\"],[8,[39,2],null,null,null]],[\"@Subsection\",\"Item\"],false,[\"freestyle/usage\",\"stacked-list\",\"modal-container\"]]",
    "moduleName": "style-guide/components/style-guide/stacked-list/index.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});