define("@bagaar/ember-pagination/components/pagination-data", ["exports", "@ember/component", "@ember/debug", "@ember/object", "@ember/object/computed", "@bagaar/ember-pagination/templates/components/pagination-data"], function (_exports, _component, _debug, _object, _computed, _paginationData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DISABLED = null;
  const FIRST_PAGE = 1;

  var _default = _component.default.extend({
    /**
     * Arguments
     */
    currentPage: null,
    totalItems: null,
    itemsPerPage: null,
    pageRange: null,
    pageMargins: 1,

    /**
     * State
     */
    layout: _paginationData.default,
    tagName: '',

    /**
     * Computed
     */
    lastPage: (0, _computed.alias)('totalPages'),
    _currentPage: (0, _object.computed)('currentPage', 'lastPage', function () {
      (true && !(this.currentPage >= FIRST_PAGE && this.currentPage <= this.lastPage) && (0, _debug.assert)(`@currentPage should be a value between ${FIRST_PAGE} and ${this.lastPage}`, this.currentPage >= FIRST_PAGE && this.currentPage <= this.lastPage));
      return clamp(this.currentPage, FIRST_PAGE, this.lastPage);
    }),
    previousPage: (0, _object.computed)('isFirstPage', '_currentPage', function () {
      return this.isFirstPage ? DISABLED : this._currentPage - 1;
    }),
    nextPage: (0, _object.computed)('isLastPage', '_currentPage', function () {
      return this.isLastPage ? DISABLED : this._currentPage + 1;
    }),
    isFirstPage: (0, _object.computed)('_currentPage', function () {
      return this._currentPage === FIRST_PAGE;
    }),
    isLastPage: (0, _object.computed)('_currentPage', 'lastPage', function () {
      return this._currentPage === this.lastPage;
    }),
    totalPages: (0, _object.computed)('totalItems', 'itemsPerPage', function () {
      const totalPages = Math.ceil(this.totalItems / this.itemsPerPage); // There should always be a page

      return isNaN(totalPages) || totalPages === 0 ? 1 : totalPages;
    }),
    activeItems: (0, _object.computed)('_currentPage', 'totalItems', 'itemsPerPage', function () {
      if (this.itemsPerPage * this._currentPage > this.totalItems) {
        return this.totalItems % this.itemsPerPage;
      }

      return this.itemsPerPage;
    }),
    allPages: (0, _object.computed)('lastPage', function () {
      return range(FIRST_PAGE, this.lastPage);
    }),
    pageRangePages: (0, _object.computed)('allPages', '_currentPage', 'pageMarginsThreshold', 'pageRange', 'pageRangeLowerLimit', 'pageRangeUpperLimit', 'totalPages', function () {
      const pageRange = this.pageRange;

      if (!pageRange) {
        return null;
      }

      if (this.totalPages < this.pageMarginsThreshold) {
        return this.allPages;
      }

      const pageRangeOffset = Math.floor(pageRange / 2);
      let pageRangeStart = this._currentPage - pageRangeOffset;
      let pageRangeEnd = this._currentPage + pageRangeOffset;

      if (pageRangeStart < this.pageRangeLowerLimit) {
        pageRangeStart = this.pageRangeLowerLimit;
        pageRangeEnd = pageRangeStart + pageRange - 1;
      }

      if (pageRangeEnd > this.pageRangeUpperLimit) {
        pageRangeStart = this.pageRangeUpperLimit - pageRange + 1;
        pageRangeEnd = this.pageRangeUpperLimit;
      }

      return range(pageRangeStart, pageRangeEnd);
    }),
    startMarginPages: (0, _object.computed)('pageMargins', 'pageMarginsThreshold', 'pageRange', 'totalPages', function () {
      if (!this.pageRange) {
        return null;
      }

      if (this.totalPages < this.pageMarginsThreshold) {
        return [];
      }

      return range(FIRST_PAGE, this.pageMargins);
    }),
    endMarginPages: (0, _object.computed)('pageMargins', 'pageMarginsThreshold', 'pageRange', 'pageRangeUpperLimit', 'totalPages', function () {
      if (!this.pageRange) {
        return null;
      }

      if (this.totalPages < this.pageMarginsThreshold) {
        return [];
      }

      return range(this.pageRangeUpperLimit + 1, this.totalPages);
    }),
    shouldShowLowerBreak: (0, _object.computed)('pageRange', 'pageRangePages', 'pageRangeLowerLimit', 'pageMarginsThreshold', 'totalPages', function () {
      if (!this.pageRange) {
        return false;
      }

      if (this.totalPages < this.pageMarginsThreshold) {
        return false;
      }

      return this.pageRangePages.length && this.pageRangePages[0] !== this.pageRangeLowerLimit;
    }),
    shouldShowUpperBreak: (0, _object.computed)('pageRange', 'pageRangePages', 'pageRangeUpperLimit', 'pageMarginsThreshold', 'totalPages', function () {
      if (!this.pageRange) {
        return false;
      }

      if (this.totalPages < this.pageMarginsThreshold) {
        return false;
      }

      const pageRangePages = this.pageRangePages;
      return pageRangePages.length && pageRangePages[pageRangePages.length - 1] !== this.pageRangeUpperLimit;
    }),
    pageRangeLowerLimit: (0, _object.computed)('pageMargins', function () {
      return this.pageMargins + 1;
    }),
    pageRangeUpperLimit: (0, _object.computed)('pageMargins', 'totalPages', function () {
      return this.totalPages - this.pageMargins;
    }),
    pageMarginsThreshold: (0, _object.computed)('pageMargins', 'pageRange', function () {
      return this.pageRange + this.pageMargins * 2;
    }),

    /**
     * Hooks
     */
    init() {
      this._super(...arguments);

      const itemsPerPage = this.itemsPerPage;
      const pageRange = this.pageRange;
      const pageMargins = this.pageMargins;
      (true && !(isNumber(this.currentPage)) && (0, _debug.assert)('@currentPage is required and should be a number', isNumber(this.currentPage)));
      (true && !(isNumber(this.totalItems)) && (0, _debug.assert)('@totalItems is required and should be a number', isNumber(this.totalItems)));
      (true && !(!itemsPerPage || isNumber(itemsPerPage)) && (0, _debug.assert)('@itemsPerPage is required and should be a number', !itemsPerPage || isNumber(itemsPerPage)));
      (true && !(!pageRange || isNumber(pageRange) && pageRange % 2 !== 0) && (0, _debug.assert)('@pageRange should be an uneven number to make sure that the active page is always center aligned', !pageRange || isNumber(pageRange) && pageRange % 2 !== 0));
      (true && !(!pageRange || isNumber(pageMargins) && pageMargins > 0) && (0, _debug.assert)('@pageMargins should be a number higher than 0', !pageRange || isNumber(pageMargins) && pageMargins > 0));
    }

  }); // TODO: add ember-auto-import and use lodash's range util instead
  // Src: https://dev.to/ycmjason/how-to-create-range-in-javascript-539i


  _exports.default = _default;

  function range(start, end) {
    return new Array(end - start + 1).fill(undefined).map((_, i) => i + start);
  }

  function isNumber(value) {
    return typeof value === 'number' && !isNaN(value);
  }

  function clamp(number, min, max) {
    return Math.min(Math.max(number, min), max);
  }
});