define("style-guide/components/style-guide/label/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <@Subsection @name="Labels">
    <FreestyleCollection as |collection|>
  
      <collection.variant @key="default">
        <Freestyle::Usage @name="Default">
          <:example>
            <Label class="mb-1" for="label-default-input">
              Label
            </Label>
            <InputField id="label-default-input" />
          </:example>
        </Freestyle::Usage>
      </collection.variant>
  
      <collection.variant @key="required">
        <Freestyle::Usage @name="Required">
          <:example>
            <Label @isRequired={{true}} class="mb-1" for="label-required-input">
              Label
            </Label>
            <InputField id="label-required-input" />
          </:example>
        </Freestyle::Usage>
      </collection.variant>
  
    </FreestyleCollection>
  </@Subsection>
  */
  {
    "id": "IsKQvwuM",
    "block": "[[[8,[30,1],null,[[\"@name\"],[\"Labels\"]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n\\n    \"],[8,[30,2,[\"variant\"]],null,[[\"@key\"],[\"default\"]],[[\"default\"],[[[[1,\"\\n      \"],[8,[39,1],null,[[\"@name\",\"@source\"],[\"Default\",\"       \\n<Label class=\\\"mb-1\\\" for=\\\"label-default-input\\\">\\n  Label\\n</Label>\\n<InputField id=\\\"label-default-input\\\" />\\n        \"]],[[\"example\"],[[[[1,\"\\n          \"],[8,[39,2],[[24,0,\"mb-1\"],[24,\"for\",\"label-default-input\"]],null,[[\"default\"],[[[[1,\"\\n            Label\\n          \"]],[]]]]],[1,\"\\n          \"],[8,[39,3],[[24,1,\"label-default-input\"]],null,null],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[30,2,[\"variant\"]],null,[[\"@key\"],[\"required\"]],[[\"default\"],[[[[1,\"\\n      \"],[8,[39,1],null,[[\"@name\",\"@source\"],[\"Required\",\"       \\n<Label @isRequired={{true}} class=\\\"mb-1\\\" for=\\\"label-required-input\\\">\\n  Label\\n</Label>\\n<InputField id=\\\"label-required-input\\\" />\\n        \"]],[[\"example\"],[[[[1,\"\\n          \"],[8,[39,2],[[24,0,\"mb-1\"],[24,\"for\",\"label-required-input\"]],[[\"@isRequired\"],[true]],[[\"default\"],[[[[1,\"\\n            Label\\n          \"]],[]]]]],[1,\"\\n          \"],[8,[39,3],[[24,1,\"label-required-input\"]],null,null],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n  \"]],[2]]]]],[1,\"\\n\"]],[]]]]]],[\"@Subsection\",\"collection\"],false,[\"freestyle-collection\",\"freestyle/usage\",\"label\",\"input-field\"]]",
    "moduleName": "style-guide/components/style-guide/label/index.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});