define("ember-link/services/link-manager", ["exports", "@ember/application", "@ember/object", "@ember/object/events", "@ember/service", "@glimmer/tracking", "ember-link/link"], function (_exports, _application, _object, _events, _service, _tracking, _link) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let LinkManagerService = (_dec = (0, _service.inject)('router'), (_class = class LinkManagerService extends _service.default {
    /**
     * The `RouterService` instance to be used by the generated `Link` instances.
     */

    /**
     * Whether the router has been initialized.
     * This will be `false` in render tests.
     *
     * @see https://github.com/buschtoens/ember-link/issues/126
     */
    get isRouterInitialized() {
      // Ideally we would use the public `router` service here, but accessing
      // the `currentURL` on that service automatically starts the routing layer
      // as a side-effect, which is not our intention here. Once or if Ember.js
      // provides a flag on the `router` service to figure out if routing was
      // already initialized we should switch back to the public service instead.
      //
      // Inspiration for this workaround was taken from the `currentURL()` test
      // helper (see https://github.com/emberjs/ember-test-helpers/blob/v2.1.4/addon-test-support/@ember/test-helpers/setup-application-context.ts#L180)
      return Boolean((0, _application.getOwner)(this).lookup('router:main').currentURL);
    }
    /**
     * The currently active `Transition` objects.
     */


    get currentTransitionStack() {
      return this._currentTransitionStack;
    }
    /**
     * Creates a `Link` instance.
     */


    createLink(linkParams) {
      return new _link.default(this, linkParams);
    }
    /**
     * Creates a `UILink` instance.
     */


    createUILink(linkParams, uiParams) {
      return new _link.UILink(this, { ...linkParams,
        ...uiParams
      });
    }
    /**
     * Deserializes the `LinkParams` to be passed to `createLink` / `createUILink`
     * from a URL.
     *
     * If the URL cannot be recognized by the router, an error is thrown.
     */


    getLinkParamsFromURL(url) {
      const routeInfo = this.router.recognize(url);
      return LinkManagerService.getLinkParamsFromRouteInfo(routeInfo);
    }
    /**
     * Converts a `RouteInfo` object into `LinkParams`.
     */


    static getLinkParamsFromRouteInfo(routeInfo) {
      const models = routeInfo.paramNames.map(name => routeInfo.params[name]);
      return {
        route: routeInfo.name,
        query: routeInfo.queryParams,
        models
      };
    } // eslint-disable-next-line @typescript-eslint/ban-types


    constructor(properties) {
      super(properties); // Ignore `Argument of type '"routeWillChange"' is not assignable to parameter of type ...`
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore

      _initializerDefineProperty(this, "_currentTransitionStack", _descriptor, this);

      _initializerDefineProperty(this, "router", _descriptor2, this);

      (0, _events.addListener)(this.router, 'routeWillChange', this.handleRouteWillChange); // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore

      (0, _events.addListener)(this.router, 'routeDidChange', this.handleRouteDidChange);
    }

    willDestroy() {
      super.willDestroy(); // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore

      (0, _events.removeListener)(this.router, 'routeWillChange', this.handleRouteWillChange); // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore

      (0, _events.removeListener)(this.router, 'routeDidChange', this.handleRouteDidChange);
    }

    handleRouteWillChange(transition) {
      this._currentTransitionStack = [...(this._currentTransitionStack || []), transition];
    }

    handleRouteDidChange() {
      this._currentTransitionStack = undefined;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "_currentTransitionStack", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "handleRouteWillChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleRouteWillChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleRouteDidChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleRouteDidChange"), _class.prototype)), _class));
  _exports.default = LinkManagerService;
});