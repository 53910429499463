define("ember-freestyle/components/freestyle-collection/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/service", "@ember/object/computed", "@ember/array", "@ember/runloop", "@ember/object"], function (_exports, _component, _templateFactory, _service, _computed, _array, _runloop, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="FreestyleCollection {{if @inline 'FreestyleCollection--inline'}}">
    {{#if this.hasLabels}}
      <div class="FreestyleCollection-title">
        {{@title}}
      </div>
    {{/if}}
    {{#if this.showVariantList}}
      <FreestyleVariantList @variants={{this.variants}} @activeKey={{this.activeKey}} @onClickVariant={{this.setKey}} />
    {{/if}}
    {{yield
      (hash
        variant=(component "freestyle-variant" collection=this)
      )
    }}
  </div>
  
  */
  {
    "id": "IN7tYp3V",
    "block": "[[[10,0],[15,0,[29,[\"FreestyleCollection \",[52,[30,1],\"FreestyleCollection--inline\"]]]],[12],[1,\"\\n\"],[41,[30,0,[\"hasLabels\"]],[[[1,\"    \"],[10,0],[14,0,\"FreestyleCollection-title\"],[12],[1,\"\\n      \"],[1,[30,2]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[41,[30,0,[\"showVariantList\"]],[[[1,\"    \"],[8,[39,1],null,[[\"@variants\",\"@activeKey\",\"@onClickVariant\"],[[30,0,[\"variants\"]],[30,0,[\"activeKey\"]],[30,0,[\"setKey\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"  \"],[18,3,[[28,[37,3],null,[[\"variant\"],[[50,\"freestyle-variant\",0,null,[[\"collection\"],[[30,0]]]]]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@inline\",\"@title\",\"&default\"],false,[\"if\",\"freestyle-variant-list\",\"yield\",\"hash\",\"component\"]]",
    "moduleName": "ember-freestyle/components/freestyle-collection/index.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend((_obj = {
    tagName: '',
    emberFreestyle: (0, _service.inject)(),
    showLabels: (0, _computed.and)('emberFreestyle.notFocused', 'emberFreestyle.showLabels'),
    hasLabels: (0, _computed.and)('showLabels', 'title'),
    showVariantList: (0, _computed.not)('emberFreestyle.focus'),
    defaultKey: 'all',
    activeKey: (0, _object.computed)('defaultKey', 'key', 'emberFreestyle.focus', function () {
      if (this.emberFreestyle.focus) {
        return 'all';
      }

      return this.key || this.defaultKey;
    }),

    registerVariant(variantKey) {
      (0, _runloop.next)(() => {
        if (this.isDestroyed) {
          return;
        }

        let variants = this.variants || (0, _array.A)(['all']);

        if (!variants.includes(variantKey)) {
          variants.pushObject(variantKey);
        }

        this.set('variants', variants);
      });
    },

    setKey(key) {
      this.set('key', key);
    }

  }, (_applyDecoratedDescriptor(_obj, "setKey", [_object.action], Object.getOwnPropertyDescriptor(_obj, "setKey"), _obj)), _obj)));

  _exports.default = _default;
});