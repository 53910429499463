define("ember-freestyle/components/freestyle-palette/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/array", "@ember/object"], function (_exports, _component, _templateFactory, _array, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class='FreestylePalette'>
    <div class='FreestylePalette-title'>{{this.title}}</div>
    <div class='FreestylePalette-description'>{{this.description}}</div>
    {{#each this.colors as |color|}}
      <FreestylePaletteItem @color={{color}} />
    {{/each}}
    {{yield}}
  </div>
  
  */
  {
    "id": "5mx0wr/U",
    "block": "[[[10,0],[14,0,\"FreestylePalette\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"FreestylePalette-title\"],[12],[1,[30,0,[\"title\"]]],[13],[1,\"\\n  \"],[10,0],[14,0,\"FreestylePalette-description\"],[12],[1,[30,0,[\"description\"]]],[13],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"colors\"]]],null]],null],null,[[[1,\"    \"],[8,[39,2],null,[[\"@color\"],[[30,1]]],null],[1,\"\\n\"]],[1]],null],[1,\"  \"],[18,2,null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"color\",\"&default\"],false,[\"each\",\"-track-array\",\"freestyle-palette-item\",\"yield\"]]",
    "moduleName": "ember-freestyle/components/freestyle-palette/index.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend({
    tagName: '',
    title: 'Freestyle Palette',
    description: 'Represents all colors used in this project.',
    colors: (0, _object.computed)('colorPalette', function () {
      return (0, _array.A)(Object.keys(this.colorPalette).map(k => {
        return this.colorPalette[k];
      }));
    })
  }));

  _exports.default = _default;
});