define("ember-apollo-client/-private/apollo/setup-hooks", ["exports", "@ember/component", "@ember/routing/route"], function (_exports, _component, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = setupHooks;
  const hooks = {
    willDestroyElement() {
      this.unsubscribeAll(false);
    },

    beforeModel() {
      this.markSubscriptionsStale();
    },

    resetController(_, isExiting) {
      this.unsubscribeAll(!isExiting);
    },

    willDestroy() {
      if (this.unsubscribeAll) {
        this.unsubscribeAll(false);
      }
    }

  };

  function installHook(queryManager, context, hookName) {
    let hook = hooks[hookName].bind(queryManager);
    let originalHook = context[hookName];

    context[hookName] = function () {
      if (typeof originalHook === 'function') {
        originalHook.call(this, ...arguments);
      }

      hook.call(queryManager, ...arguments);
    };
  }

  function setupHooks(queryManager, context) {
    if (context instanceof _component.default) {
      installHook(queryManager, context, 'willDestroyElement');
    } else if (context instanceof _route.default) {
      installHook(queryManager, context, 'beforeModel');
      installHook(queryManager, context, 'resetController');
      installHook(queryManager, context, 'willDestroy');
    } else {
      installHook(queryManager, context, 'willDestroy');
    }
  }
});