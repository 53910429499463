define("ember-freestyle/components/freestyle/usage/string/control/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/object", "@glimmer/component"], function (_exports, _component, _templateFactory, _object, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{! template-lint-disable require-input-label }}
  
  {{#if @options}}
    <select {{on 'change' this.callOnInput}}>
      {{#each @options as |optionValue|}}
        <option value={{optionValue}} selected={{eq optionValue @value}}>{{optionValue}}</option>
      {{/each}}
    </select>
  {{else}}
    <input
      type="text"
      value={{@value}}
      {{on this.inputEvent this.callOnInput}}
    />
  {{/if}}
  */
  {
    "id": "rDTFSkgv",
    "block": "[[[1,\"\\n\"],[41,[30,1],[[[1,\"  \"],[11,\"select\"],[4,[38,1],[\"change\",[30,0,[\"callOnInput\"]]],null],[12],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,1]],null]],null],null,[[[1,\"      \"],[10,\"option\"],[15,2,[30,2]],[15,\"selected\",[28,[37,4],[[30,2],[30,3]],null]],[12],[1,[30,2]],[13],[1,\"\\n\"]],[2]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[11,\"input\"],[16,2,[30,3]],[24,4,\"text\"],[4,[38,1],[[30,0,[\"inputEvent\"]],[30,0,[\"callOnInput\"]]],null],[12],[13],[1,\"\\n\"]],[]]]],[\"@options\",\"optionValue\",\"@value\"],false,[\"if\",\"on\",\"each\",\"-track-array\",\"eq\"]]",
    "moduleName": "ember-freestyle/components/freestyle/usage/string/control/index.hbs",
    "isStrictMode": false
  });

  let FreestyleUsageStringControlComponent = (_class = class FreestyleUsageStringControlComponent extends _component2.default {
    get inputEvent() {
      return this.args.inputEvent || 'input';
    }

    callOnInput(event) {
      this.args.onInput(event.target.value);
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "callOnInput", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "callOnInput"), _class.prototype)), _class);
  _exports.default = FreestyleUsageStringControlComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, FreestyleUsageStringControlComponent);
});