define("style-guide/utils/style-guide", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.generateColorPalette = generateColorPalette;

  function generateColorPalette(colors, colorNamePrefix) {
    return Object.keys(colors).reduce((colorPalette, colorKey) => {
      const colorValue = colors[colorKey];
      const colorName = generateColorName(colorKey, colorNamePrefix);

      if (isColorWeightObject(colorValue)) {
        Object.assign(colorPalette, generateColorPalette(colorValue, colorName));
      } else {
        colorPalette[colorName] = generateColorPaletteEntry(colorValue, colorName);
      }

      return colorPalette;
    }, {});
  }

  function generateColorName(colorName, colorNamePrefix) {
    if (colorNamePrefix) {
      return `${colorNamePrefix}-${colorName}`;
    }

    return colorName;
  }

  function generateColorPaletteEntry(colorValue, colorName) {
    return {
      base: colorValue,
      name: colorName
    };
  }

  function isColorWeightObject(colorValue) {
    return typeof colorValue === 'object';
  }
});