define("ember-freestyle/components/freestyle-usage/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/service", "@ember/object/computed", "@ember/object"], function (_exports, _component, _templateFactory, _service, _computed, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="FreestyleUsage {{if @inline 'FreestyleUsage--inline'}}">
    {{#if this.show}}
      {{#if this.hasLabels}}
        <div class="FreestyleUsage-title">
          {{@title}}
          <FreestyleFocusButton {{on 'click' this.setFocus}} class="FreestyleUsage-focusCta" />
        </div>
      {{/if}}
      <div class="FreestyleUsage-rendered">
        {{yield}}
      </div>
  
      {{#if this.hasCode}}
        <div class="FreestyleUsage-usage">
          {{#if @source}}
            <div class="FreestyleUsage-sourceContainer">
              <FreestyleSource @source={{@source}} @isDynamic={{@isDynamic}} @dynamicProperties={{this.dynamicProperties}} @title="Source" />
            </div>
          {{/if}}
        </div>
      {{/if}}
    {{/if}}
  </div>
  */
  {
    "id": "UFH2xmN3",
    "block": "[[[10,0],[15,0,[29,[\"FreestyleUsage \",[52,[30,1],\"FreestyleUsage--inline\"]]]],[12],[1,\"\\n\"],[41,[30,0,[\"show\"]],[[[41,[30,0,[\"hasLabels\"]],[[[1,\"      \"],[10,0],[14,0,\"FreestyleUsage-title\"],[12],[1,\"\\n        \"],[1,[30,2]],[1,\"\\n        \"],[8,[39,1],[[24,0,\"FreestyleUsage-focusCta\"],[4,[38,2],[\"click\",[30,0,[\"setFocus\"]]],null]],null,null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[10,0],[14,0,\"FreestyleUsage-rendered\"],[12],[1,\"\\n      \"],[18,5,null],[1,\"\\n    \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"hasCode\"]],[[[1,\"      \"],[10,0],[14,0,\"FreestyleUsage-usage\"],[12],[1,\"\\n\"],[41,[30,3],[[[1,\"          \"],[10,0],[14,0,\"FreestyleUsage-sourceContainer\"],[12],[1,\"\\n            \"],[8,[39,4],null,[[\"@source\",\"@isDynamic\",\"@dynamicProperties\",\"@title\"],[[30,3],[30,4],[30,0,[\"dynamicProperties\"]],\"Source\"]],null],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n\"]],[]],null]],[]],null],[13]],[\"@inline\",\"@title\",\"@source\",\"@isDynamic\",\"&default\"],false,[\"if\",\"freestyle-focus-button\",\"on\",\"yield\",\"freestyle-source\"]]",
    "moduleName": "ember-freestyle/components/freestyle-usage/index.hbs",
    "isStrictMode": false
  });
  /* eslint-disable ember/no-component-lifecycle-hooks */


  let FreestyleUsage = _component.default.extend((_obj = {
    tagName: '',
    emberFreestyle: (0, _service.inject)(),
    showLabels: (0, _computed.readOnly)('emberFreestyle.showLabels'),
    showCode: (0, _computed.readOnly)('emberFreestyle.showCode'),
    show: (0, _object.computed)('emberFreestyle.focus', 'slug', function () {
      let slug = this.slug;
      let focus = this.emberFreestyle.focus;

      if (focus && slug) {
        return slug.match(focus);
      }

      return true;
    }),
    defaultTheme: (0, _computed.readOnly)('emberFreestyle.defaultTheme'),
    // highlightJsTheme - passed in
    computedTheme: (0, _computed.or)('defaultTheme', 'highlightJsTheme'),
    hasLabels: (0, _computed.and)('showLabels', 'title'),
    hasCode: (0, _object.computed)('showCode', 'slug', 'source', function () {
      return this.showCode && (this.slug || this.source);
    }),

    init() {
      this._super(...arguments);

      this.dynamicProperties = this.dynamicProperties || {};
    },

    didInsertElement() {
      this._super(...arguments);

      this.emberFreestyle.ensureHljsTheme(this.computedTheme);
    },

    setFocus() {
      // eslint-disable-next-line ember/no-assignment-of-untracked-properties-used-in-tracking-contexts
      this.emberFreestyle.focus = this.slug;
    }

  }, (_applyDecoratedDescriptor(_obj, "setFocus", [_object.action], Object.getOwnPropertyDescriptor(_obj, "setFocus"), _obj)), _obj));

  FreestyleUsage.reopenClass({
    positionalParams: ['slug']
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, FreestyleUsage);

  _exports.default = _default;
});