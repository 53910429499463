define("ember-freestyle/components/freestyle/usage/object/control/index", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "json-formatter-js"], function (_exports, _component, _templateFactory, _component2, _jsonFormatterJs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @value}}
    <div class="FreestyleUsageArgument-jsonViewer">
      {{this.jsonTree}}
    </div>
  {{/if}}
  */
  {
    "id": "IhDEFNjx",
    "block": "[[[41,[30,1],[[[1,\"  \"],[10,0],[14,0,\"FreestyleUsageArgument-jsonViewer\"],[12],[1,\"\\n    \"],[1,[30,0,[\"jsonTree\"]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"@value\"],false,[\"if\"]]",
    "moduleName": "ember-freestyle/components/freestyle/usage/object/control/index.hbs",
    "isStrictMode": false
  });

  const OPTIONS = {
    animateClose: false,
    animateOpen: false,
    theme: 'dark'
  };

  class FreestyleUsageObjectControlComponent extends _component2.default {
    get jsonTree() {
      const json = this.args.value;
      const openDepth = this.args.jsonCollapseDepth;
      const jsonFormatter = new _jsonFormatterJs.default(json, openDepth, OPTIONS);
      return jsonFormatter.render();
    }

  }

  _exports.default = FreestyleUsageObjectControlComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, FreestyleUsageObjectControlComponent);
});