define("ember-freestyle/components/freestyle-typeface/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/template", "@ember/object"], function (_exports, _component, _templateFactory, _template, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="FreestyleTypeface" style={{this.fontFamilyStyle}}>
    <div class="FreestyleTypeface-previewHero">Aa</div>
    <div class="FreestyleTypeface-previewSampleContainer">
      <p class="FreestyleTypeface-previewSample">A B C D E F G H I J K L M N O P Q R S T U V W X Y Z</p>
      <p class="FreestyleTypeface-previewSample">a b c d e f g h i j k l m n o p q r s t u v w x y z</p>
      <p class="FreestyleTypeface-previewSample">0 1 2 3 4 5 6 7 8 9</p>
    </div>
  </div>
  
  */
  {
    "id": "ihvt6s3Y",
    "block": "[[[10,0],[14,0,\"FreestyleTypeface\"],[15,5,[30,0,[\"fontFamilyStyle\"]]],[12],[1,\"\\n  \"],[10,0],[14,0,\"FreestyleTypeface-previewHero\"],[12],[1,\"Aa\"],[13],[1,\"\\n  \"],[10,0],[14,0,\"FreestyleTypeface-previewSampleContainer\"],[12],[1,\"\\n    \"],[10,2],[14,0,\"FreestyleTypeface-previewSample\"],[12],[1,\"A B C D E F G H I J K L M N O P Q R S T U V W X Y Z\"],[13],[1,\"\\n    \"],[10,2],[14,0,\"FreestyleTypeface-previewSample\"],[12],[1,\"a b c d e f g h i j k l m n o p q r s t u v w x y z\"],[13],[1,\"\\n    \"],[10,2],[14,0,\"FreestyleTypeface-previewSample\"],[12],[1,\"0 1 2 3 4 5 6 7 8 9\"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "ember-freestyle/components/freestyle-typeface/index.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend({
    tagName: '',
    fontFamilyStyle: (0, _object.computed)('fontFamily', function () {
      return (0, _template.htmlSafe)(`font-family: ${this.fontFamily};`);
    })
  }));

  _exports.default = _default;
});