define("ember-freestyle/controllers/freestyle", ["exports", "@ember/service", "@ember/object/computed", "@ember/controller"], function (_exports, _service, _computed, _controller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    emberFreestyle: (0, _service.inject)(),
    queryParams: ['l', 'n', 'c', 'a', 'm', 'f', 's', 'ss'],
    l: (0, _computed.alias)('emberFreestyle.showLabels'),
    n: (0, _computed.alias)('emberFreestyle.showNotes'),
    c: (0, _computed.alias)('emberFreestyle.showCode'),
    a: (0, _computed.alias)('emberFreestyle.showApi'),
    m: (0, _computed.alias)('emberFreestyle.showMenu'),
    f: (0, _computed.alias)('emberFreestyle.focus'),
    s: (0, _computed.alias)('emberFreestyle.section'),
    ss: (0, _computed.alias)('emberFreestyle.subsection')
  });

  _exports.default = _default;
});