define("ember-freestyle/components/freestyle-variant-list/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/object"], function (_exports, _component, _templateFactory, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <ul class='FreestyleCollection-variantList'>
    {{#each @variants as |variantKey|}}
      <li
        class='FreestyleCollection-variantListItem {{if (eq @activeKey variantKey) 'FreestyleCollection-variantListItem--active'}}'
        role="button"
        onclick={{fn this.onClickItem variantKey}}
      >
        {{variantKey}}
      </li>
    {{/each}}
  </ul>
  
  */
  {
    "id": "09B0QIxz",
    "block": "[[[10,\"ul\"],[14,0,\"FreestyleCollection-variantList\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,1]],null]],null],null,[[[1,\"    \"],[10,\"li\"],[15,0,[29,[\"FreestyleCollection-variantListItem \",[52,[28,[37,3],[[30,3],[30,2]],null],\"FreestyleCollection-variantListItem--active\"]]]],[14,\"role\",\"button\"],[15,\"onclick\",[28,[37,4],[[30,0,[\"onClickItem\"]],[30,2]],null]],[12],[1,\"\\n      \"],[1,[30,2]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[2]],null],[13],[1,\"\\n\"]],[\"@variants\",\"variantKey\",\"@activeKey\"],false,[\"each\",\"-track-array\",\"if\",\"eq\",\"fn\"]]",
    "moduleName": "ember-freestyle/components/freestyle-variant-list/index.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend((_obj = {
    tagName: '',

    onClickItem(variantKey) {
      this.onClickVariant(variantKey);
    }

  }, (_applyDecoratedDescriptor(_obj, "onClickItem", [_object.action], Object.getOwnPropertyDescriptor(_obj, "onClickItem"), _obj)), _obj)));

  _exports.default = _default;
});