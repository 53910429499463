define("ember-freestyle/components/freestyle-focus-button/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <span role="button" ...attributes>
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="14" height="14" viewBox="0 0 24 24" data-code="58332" data-tags="filter_center_focus"><g transform="scale(0.0234375 0.0234375)"><path d="M512 384q52 0 90 38t38 90-38 90-90 38-90-38-38-90 38-90 90-38zM810 810v-170h86v170q0 34-26 60t-60 26h-170v-86h170zM810 128q34 0 60 26t26 60v170h-86v-170h-170v-86h170zM214 214v170h-86v-170q0-34 26-60t60-26h170v86h-170zM214 640v170h170v86h-170q-34 0-60-26t-26-60v-170h86z" /></g></svg>
  </span>
  
  */
  {
    "id": "zTMW52Kx",
    "block": "[[[11,1],[24,\"role\",\"button\"],[17,1],[12],[1,\"\\n    \"],[10,\"svg\"],[14,\"version\",\"1.1\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"xmlns:xlink\",\"http://www.w3.org/1999/xlink\",\"http://www.w3.org/2000/xmlns/\"],[14,\"width\",\"14\"],[14,\"height\",\"14\"],[14,\"viewBox\",\"0 0 24 24\"],[14,\"data-code\",\"58332\"],[14,\"data-tags\",\"filter_center_focus\"],[12],[10,\"g\"],[14,\"transform\",\"scale(0.0234375 0.0234375)\"],[12],[10,\"path\"],[14,\"d\",\"M512 384q52 0 90 38t38 90-38 90-90 38-90-38-38-90 38-90 90-38zM810 810v-170h86v170q0 34-26 60t-60 26h-170v-86h170zM810 128q34 0 60 26t26 60v170h-86v-170h-170v-86h170zM214 214v170h-86v-170q0-34 26-60t60-26h170v86h-170zM214 640v170h170v86h-170q-34 0-60-26t-26-60v-170h86z\"],[12],[13],[13],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\"],false,[]]",
    "moduleName": "ember-freestyle/components/freestyle-focus-button/index.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});