define("style-guide/components/style-guide/textarea-field/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <@Subsection @name="Textarea Fields">
    <FreestyleCollection as |collection|>
      <collection.variant @key="default">
        <Freestyle::Usage @name="Default">
          <:example>
            <TextareaField placeholder="Placeholder" />
          </:example>
        </Freestyle::Usage>
        <Freestyle::Usage @name="Error">
          <:example>
            <TextareaField @hasError={{true}} />
          </:example>
        </Freestyle::Usage>
        <Freestyle::Usage @name="Disabled">
          <:example>
            <TextareaField placeholder="Placeholder" disabled />
          </:example>
        </Freestyle::Usage>
      </collection.variant>
    </FreestyleCollection>
  </@Subsection>
  */
  {
    "id": "6CuzJTIQ",
    "block": "[[[8,[30,1],null,[[\"@name\"],[\"Textarea Fields\"]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[30,2,[\"variant\"]],null,[[\"@key\"],[\"default\"]],[[\"default\"],[[[[1,\"\\n      \"],[8,[39,1],null,[[\"@name\",\"@source\"],[\"Default\",\"       \\n<TextareaField placeholder=\\\"Placeholder\\\" />\\n        \"]],[[\"example\"],[[[[1,\"\\n          \"],[8,[39,2],[[24,\"placeholder\",\"Placeholder\"]],null,null],[1,\"\\n        \"]],[]]]]],[1,\"\\n      \"],[8,[39,1],null,[[\"@name\",\"@source\"],[\"Error\",\"       \\n<TextareaField @hasError={{true}} />\\n        \"]],[[\"example\"],[[[[1,\"\\n          \"],[8,[39,2],null,[[\"@hasError\"],[true]],null],[1,\"\\n        \"]],[]]]]],[1,\"\\n      \"],[8,[39,1],null,[[\"@name\",\"@source\"],[\"Disabled\",\"       \\n<TextareaField placeholder=\\\"Placeholder\\\" disabled />\\n        \"]],[[\"example\"],[[[[1,\"\\n          \"],[8,[39,2],[[24,\"placeholder\",\"Placeholder\"],[24,\"disabled\",\"\"]],null,null],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[2]]]]],[1,\"\\n\"]],[]]]]]],[\"@Subsection\",\"collection\"],false,[\"freestyle-collection\",\"freestyle/usage\",\"textarea-field\"]]",
    "moduleName": "style-guide/components/style-guide/textarea-field/index.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});