define("style-guide/components/style-guide/input-search/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/object", "@glimmer/component", "@glimmer/tracking"], function (_exports, _component, _templateFactory, _object, _component2, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <@Subsection @name="Input Search">
    <FreestyleCollection as |collection|>
      <collection.variant @key="default">
        <Freestyle::Usage @name="Default">
          <:example>
            <InputSearch @onSearch={{this.onSearch}} @placeholder="Search" />
            {{#if this.search}}
              <div class="mt-3">Searching for: {{this.search}}</div>
            {{/if}}
          </:example>
        </Freestyle::Usage>
      </collection.variant>
    </FreestyleCollection>
  </@Subsection>
  */
  {
    "id": "TQyXmsEI",
    "block": "[[[8,[30,1],null,[[\"@name\"],[\"Input Search\"]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[30,2,[\"variant\"]],null,[[\"@key\"],[\"default\"]],[[\"default\"],[[[[1,\"\\n      \"],[8,[39,1],null,[[\"@name\",\"@source\"],[\"Default\",\"       \\n<InputSearch @onSearch={{this.onSearch}} @placeholder=\\\"Search\\\" />\\n{{#if this.search}}\\n  <div class=\\\"mt-3\\\">Searching for: {{this.search}}</div>\\n{{/if}}\\n        \"]],[[\"example\"],[[[[1,\"\\n          \"],[8,[39,2],null,[[\"@onSearch\",\"@placeholder\"],[[30,0,[\"onSearch\"]],\"Search\"]],null],[1,\"\\n\"],[41,[30,0,[\"search\"]],[[[1,\"            \"],[10,0],[14,0,\"mt-3\"],[12],[1,\"Searching for: \"],[1,[30,0,[\"search\"]]],[13],[1,\"\\n\"]],[]],null],[1,\"        \"]],[]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[2]]]]],[1,\"\\n\"]],[]]]]]],[\"@Subsection\",\"collection\"],false,[\"freestyle-collection\",\"freestyle/usage\",\"input-search\",\"if\"]]",
    "moduleName": "style-guide/components/style-guide/input-search/index.hbs",
    "isStrictMode": false
  });

  let StyleGuideInputSearchComponent = (_class = class StyleGuideInputSearchComponent extends _component2.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "search", _descriptor, this);
    }

    onSearch(search) {
      this.search = search;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "search", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onSearch", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onSearch"), _class.prototype)), _class);
  _exports.default = StyleGuideInputSearchComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, StyleGuideInputSearchComponent);
});