define("ember-freestyle/components/freestyle-source/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/service", "@ember/object"], function (_exports, _component, _templateFactory, _service, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.renderableSource}}
    <div class="FreestyleSource-title">
      Source
    </div>
    <pre class="handlebars" tabindex="0">{{this.renderableSource}}</pre>
  {{/if}}
  
  */
  {
    "id": "bYsnd5G+",
    "block": "[[[41,[30,0,[\"renderableSource\"]],[[[1,\"  \"],[10,0],[14,0,\"FreestyleSource-title\"],[12],[1,\"\\n    Source\\n  \"],[13],[1,\"\\n  \"],[10,\"pre\"],[14,0,\"handlebars\"],[14,\"tabindex\",\"0\"],[12],[1,[30,0,[\"renderableSource\"]]],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\"]]",
    "moduleName": "ember-freestyle/components/freestyle-source/index.hbs",
    "isStrictMode": false
  });
  /* eslint-disable ember/no-component-lifecycle-hooks */

  /* eslint-disable ember/require-tagless-components */


  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend({
    initialHighlightComplete: false,
    useDoubleQuotesForStrings: false,
    emberFreestyle: (0, _service.inject)(),

    _dynamafy(sourceCode) {
      let dynamicProperties = this.dynamicProperties;
      Object.keys(dynamicProperties).forEach(property => {
        let propertyValue = (0, _object.get)(dynamicProperties, `${property}.value`);
        let type = typeof propertyValue;
        let quote = this.useDoubleQuotesForStrings ? `"` : `'`;
        let angleAssignmentVal = type === 'string' ? `${quote}${propertyValue}${quote}` : `{{${propertyValue}}}`;
        let curlyAssignmentVal = type === 'string' ? `${quote}${propertyValue}${quote}` : propertyValue;
        let expressionVal = propertyValue; // First, replace in-block dynamic properties

        sourceCode = sourceCode.replace(`={{dynamic.${property}}}`, `=${angleAssignmentVal}`);
        sourceCode = sourceCode.replace(`=dynamic.${property}`, `=${curlyAssignmentVal}`);
        sourceCode = sourceCode.replace(`{{dynamic.${property}}}`, expressionVal);
      });
      return sourceCode;
    },

    renderableSource: (0, _object.computed)('dynamicProperties', 'element', 'initialHighlightComplete', 'isDynamic', 'source', function () {
      let result = this.source.replace(/^(\s*\n)*/, '').replace(/\s*$/, '');

      if (this.isDynamic) {
        result = this._dynamafy(result);
      }

      if (this.initialHighlightComplete) {
        let pre = this.element.querySelector('pre');

        if (pre) {
          // highlight.js breaks binding, so we need to manually reset the innerText on changes
          pre.innerText = result; // ...and then do the syntax highlighting again

          this.emberFreestyle.highlight(pre);
        }
      }

      return result;
    }),

    init() {
      this._super(...arguments);

      (0, _object.set)(this, 'dynamicProperties', this.dynamicProperties || {});
    },

    didInsertElement() {
      this._super(...arguments);

      let pre = this.element.querySelector('pre');

      if (pre && this.renderableSource) {
        this.emberFreestyle.highlight(pre);
      }

      this.set('initialHighlightComplete', true);
    }

  }));

  _exports.default = _default;
});