define("style-guide/initializers/style-guide", ["exports", "mobility-buddy-frontend/router"], function (_exports, _router) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;

  function initialize() {
    _router.default.map(function () {
      this.route('style-guide');
    });
  }

  var _default = {
    initialize
  };
  _exports.default = _default;
});