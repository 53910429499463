define("ember-intl/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [["en-us", {
    "appTitle": "mobility-buddy-frontend",
    "components": {
      "applicationLayout": {
        "header": {
          "signOutLink": "Sign out"
        }
      },
      "confirmModal": {
        "cancelButtonText": "Cancel",
        "confirmButtonText": "Confirm"
      },
      "select": {
        "noResults": "No results",
        "placeholder": "Choose"
      }
    },
    "notFound": {
      "title": "Page Not Found"
    }
  }]];
  _exports.default = _default;
});