define("ember-freestyle/components/freestyle-showdown-content/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/debug", "@ember/service"], function (_exports, _component, _templateFactory, _debug, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <MarkdownToHtml @markdown={{@text}} @tables={{true}} />
  
  */
  {
    "id": "Euf4rGE/",
    "block": "[[[8,[39,0],null,[[\"@markdown\",\"@tables\"],[[30,1],true]],null],[1,\"\\n\"]],[\"@text\"],false,[\"markdown-to-html\"]]",
    "moduleName": "ember-freestyle/components/freestyle-showdown-content/index.hbs",
    "isStrictMode": false
  });
  /* eslint-disable ember/no-component-lifecycle-hooks */

  /* eslint-disable ember/require-tagless-components */


  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend({
    emberFreestyle: (0, _service.inject)(),

    didInsertElement() {
      this._super(...arguments);

      (true && !(false) && (0, _debug.deprecate)('The `<FreestyleShowdownContent />` component is deprecated. Please use `ember-cli-showdown` directly if you need Markdown support.', false, {
        for: 'ember-freestyle',
        id: 'freestyle-showdown-content-component',
        since: '0.13.0',
        until: '0.14.0'
      }));
      let pre = this.element.getElementsByTagName('pre');

      if (pre[0]) {
        this.emberFreestyle.highlight(pre[0]);
      }
    }

  }));

  _exports.default = _default;
});