define("ember-freestyle/components/freestyle/usage/bool/index", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Freestyle::Usage::Argument
    @type="Boolean"
    @name={{@name}}
    @description={{@description}}
    @required={{@required}}
    @defaultValue={{@defaultValue}}
  >
    <Freestyle::Usage::Bool::Control
      @value={{@value}}
      @onInput={{@onInput}}
    />
  </Freestyle::Usage::Argument>
  */
  {
    "id": "JY34pQkz",
    "block": "[[[8,[39,0],null,[[\"@type\",\"@name\",\"@description\",\"@required\",\"@defaultValue\"],[\"Boolean\",[30,1],[30,2],[30,3],[30,4]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,1],null,[[\"@value\",\"@onInput\"],[[30,5],[30,6]]],null],[1,\"\\n\"]],[]]]]]],[\"@name\",\"@description\",\"@required\",\"@defaultValue\",\"@value\",\"@onInput\"],false,[\"freestyle/usage/argument\",\"freestyle/usage/bool/control\"]]",
    "moduleName": "ember-freestyle/components/freestyle/usage/bool/index.hbs",
    "isStrictMode": false
  });

  let FreestyleUsageBoolComponent = (_class = class FreestyleUsageBoolComponent extends _component2.default {
    onInput(event) {
      this.args.onInput(event.target.checked);
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "onInput", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onInput"), _class.prototype)), _class);
  _exports.default = FreestyleUsageBoolComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, FreestyleUsageBoolComponent);
});