define("ember-freestyle/components/freestyle/usage/string/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Freestyle::Usage::Argument
    @type="String"
    @name={{@name}}
    @description={{@description}}
    @required={{@required}}
    @array={{@array}}
    @defaultValue={{@defaultValue}}
  >
    <Freestyle::Usage::String::Control
      @options={{@options}}
      @value={{@value}}
      @onInput={{@onInput}}
    />
  </Freestyle::Usage::Argument>
  */
  {
    "id": "yY3LPKRA",
    "block": "[[[8,[39,0],null,[[\"@type\",\"@name\",\"@description\",\"@required\",\"@array\",\"@defaultValue\"],[\"String\",[30,1],[30,2],[30,3],[30,4],[30,5]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,1],null,[[\"@options\",\"@value\",\"@onInput\"],[[30,6],[30,7],[30,8]]],null],[1,\"\\n\"]],[]]]]]],[\"@name\",\"@description\",\"@required\",\"@array\",\"@defaultValue\",\"@options\",\"@value\",\"@onInput\"],false,[\"freestyle/usage/argument\",\"freestyle/usage/string/control\"]]",
    "moduleName": "ember-freestyle/components/freestyle/usage/string/index.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});