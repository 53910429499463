define("style-guide/components/style-guide/spinner/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <@Subsection @name="Spinners">
    <FreestyleCollection as |Collection|>
      <Collection.variant @key="default">
        <Freestyle::Usage @name="Default">
          <:example>
            <Spinner />
          </:example>
        </Freestyle::Usage>
        <Freestyle::Usage @name="Size Sm">
          <:example>
            <Spinner @size="sm" />
          </:example>
        </Freestyle::Usage>
        <Freestyle::Usage @name="Size Lg">
          <:example>
            <Spinner @size="lg" />
          </:example>
        </Freestyle::Usage>
        <Freestyle::Usage @name="Size Xl">
          <:example>
            <Spinner @size="xl" />
          </:example>
        </Freestyle::Usage>
      </Collection.variant>
  
      <Collection.variant @key="color">
        <Freestyle::Usage @name="Color">
          <:example>
            <Spinner class="border-primary-400" />
          </:example>
        </Freestyle::Usage>
      </Collection.variant>
    </FreestyleCollection>
  </@Subsection>
  */
  {
    "id": "6I3upSmU",
    "block": "[[[8,[30,1],null,[[\"@name\"],[\"Spinners\"]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[30,2,[\"variant\"]],null,[[\"@key\"],[\"default\"]],[[\"default\"],[[[[1,\"\\n      \"],[8,[39,1],null,[[\"@name\",\"@source\"],[\"Default\",\"       \\n<Spinner />\\n        \"]],[[\"example\"],[[[[1,\"\\n          \"],[8,[39,2],null,null,null],[1,\"\\n        \"]],[]]]]],[1,\"\\n      \"],[8,[39,1],null,[[\"@name\",\"@source\"],[\"Size Sm\",\"       \\n<Spinner @size=\\\"sm\\\" />\\n        \"]],[[\"example\"],[[[[1,\"\\n          \"],[8,[39,2],null,[[\"@size\"],[\"sm\"]],null],[1,\"\\n        \"]],[]]]]],[1,\"\\n      \"],[8,[39,1],null,[[\"@name\",\"@source\"],[\"Size Lg\",\"       \\n<Spinner @size=\\\"lg\\\" />\\n        \"]],[[\"example\"],[[[[1,\"\\n          \"],[8,[39,2],null,[[\"@size\"],[\"lg\"]],null],[1,\"\\n        \"]],[]]]]],[1,\"\\n      \"],[8,[39,1],null,[[\"@name\",\"@source\"],[\"Size Xl\",\"       \\n<Spinner @size=\\\"xl\\\" />\\n        \"]],[[\"example\"],[[[[1,\"\\n          \"],[8,[39,2],null,[[\"@size\"],[\"xl\"]],null],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[30,2,[\"variant\"]],null,[[\"@key\"],[\"color\"]],[[\"default\"],[[[[1,\"\\n      \"],[8,[39,1],null,[[\"@name\",\"@source\"],[\"Color\",\"       \\n<Spinner class=\\\"border-primary-400\\\" />\\n        \"]],[[\"example\"],[[[[1,\"\\n          \"],[8,[39,2],[[24,0,\"border-primary-400\"]],null,null],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[2]]]]],[1,\"\\n\"]],[]]]]]],[\"@Subsection\",\"Collection\"],false,[\"freestyle-collection\",\"freestyle/usage\",\"spinner\"]]",
    "moduleName": "style-guide/components/style-guide/spinner/index.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});