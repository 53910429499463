define("style-guide/components/style-guide/external-link/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <@Subsection @name="External Link">
    <Freestyle::Usage @name="Default">
      <:example>
        <ExternalLink @url="https://bagaar.be">Link to Bagaar Website</ExternalLink>
      </:example>
    </Freestyle::Usage>
  
    <Freestyle::Usage @name="Without an icon">
      <:example>
        <ExternalLink
          @shouldRenderIcon={{false}}
          @url="https://bagaar.be"
        >
          Link to Bagaar Website
        </ExternalLink>
      </:example>
    </Freestyle::Usage>
  
    <Freestyle::Usage @name="Custom colors">
      <:example>
        <ExternalLink
          @shouldRenderIcon={{true}}
          @url="https://bagaar.be"
          class="duration-200 ease-in-out text-success-400 transition-colors hover:text-success-700"
        >
          Link to Bagaar Website
        </ExternalLink>
      </:example>
    </Freestyle::Usage>
  </@Subsection>
  */
  {
    "id": "nfBoMorK",
    "block": "[[[8,[30,1],null,[[\"@name\"],[\"External Link\"]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,0],null,[[\"@name\",\"@source\"],[\"Default\",\"       \\n<ExternalLink @url=\\\"https://bagaar.be\\\">Link to Bagaar Website</ExternalLink>\\n    \"]],[[\"example\"],[[[[1,\"\\n      \"],[8,[39,1],null,[[\"@url\"],[\"https://bagaar.be\"]],[[\"default\"],[[[[1,\"Link to Bagaar Website\"]],[]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n  \"],[8,[39,0],null,[[\"@name\",\"@source\"],[\"Without an icon\",\"       \\n<ExternalLink\\n  @shouldRenderIcon={{false}}\\n  @url=\\\"https://bagaar.be\\\"\\n>\\n  Link to Bagaar Website\\n</ExternalLink>\\n    \"]],[[\"example\"],[[[[1,\"\\n      \"],[8,[39,1],null,[[\"@shouldRenderIcon\",\"@url\"],[false,\"https://bagaar.be\"]],[[\"default\"],[[[[1,\"\\n        Link to Bagaar Website\\n      \"]],[]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n  \"],[8,[39,0],null,[[\"@name\",\"@source\"],[\"Custom colors\",\"       \\n<ExternalLink\\n  @shouldRenderIcon={{true}}\\n  @url=\\\"https://bagaar.be\\\"\\n  class=\\\"duration-200 ease-in-out text-success-400 transition-colors hover:text-success-700\\\"\\n>\\n  Link to Bagaar Website\\n</ExternalLink>\\n    \"]],[[\"example\"],[[[[1,\"\\n      \"],[8,[39,1],[[24,0,\"duration-200 ease-in-out text-success-400 transition-colors hover:text-success-700\"]],[[\"@shouldRenderIcon\",\"@url\"],[true,\"https://bagaar.be\"]],[[\"default\"],[[[[1,\"\\n        Link to Bagaar Website\\n      \"]],[]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]]]]]],[\"@Subsection\"],false,[\"freestyle/usage\",\"external-link\"]]",
    "moduleName": "style-guide/components/style-guide/external-link/index.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});