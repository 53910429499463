define("ember-freestyle/components/freestyle/usage/argument/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/utils", "@glimmer/component"], function (_exports, _component, _templateFactory, _utils, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <tr class="FreestyleUsageArgument">
    <td class="FreestyleUsageArgument-name">
      {{#if @name}}{{@name}}{{/if}}
    </td>
  
    <td class="FreestyleUsageArgument-description">
      <span class="FreestyleUsageArgument-type u-codePill">
        {{this.typeLabel}}
      </span>
  
      {{@description}}
    </td>
  
    <td class="FreestyleUsageArgument-details">
      {{#if @required}}
        <span class="FreestyleUsageArgument-required">Required</span>
      {{/if}}
      {{#if this.shouldRenderDefaultValue}}
        <span class="FreestyleUsageArgument-default u-codePill">
          {{@defaultValue}}
        </span>
      {{/if}}
    </td>
  
    <td class={{concat
      "FreestyleUsageArgument-controls "
      "FreestyleUsageArgument-controls--" @type
    }}>
      {{#unless @hideControls}}
        {{#if (has-block)}}
            {{yield}}
        {{/if}}
      {{/unless}}
    </td>
  </tr>
  
  */
  {
    "id": "vDPcacff",
    "block": "[[[10,\"tr\"],[14,0,\"FreestyleUsageArgument\"],[12],[1,\"\\n  \"],[10,\"td\"],[14,0,\"FreestyleUsageArgument-name\"],[12],[1,\"\\n    \"],[41,[30,1],[[[1,[30,1]]],[]],null],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,\"td\"],[14,0,\"FreestyleUsageArgument-description\"],[12],[1,\"\\n    \"],[10,1],[14,0,\"FreestyleUsageArgument-type u-codePill\"],[12],[1,\"\\n      \"],[1,[30,0,[\"typeLabel\"]]],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[1,[30,2]],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,\"td\"],[14,0,\"FreestyleUsageArgument-details\"],[12],[1,\"\\n\"],[41,[30,3],[[[1,\"      \"],[10,1],[14,0,\"FreestyleUsageArgument-required\"],[12],[1,\"Required\"],[13],[1,\"\\n\"]],[]],null],[41,[30,0,[\"shouldRenderDefaultValue\"]],[[[1,\"      \"],[10,1],[14,0,\"FreestyleUsageArgument-default u-codePill\"],[12],[1,\"\\n        \"],[1,[30,4]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\\n  \"],[10,\"td\"],[15,0,[28,[37,1],[\"FreestyleUsageArgument-controls \",\"FreestyleUsageArgument-controls--\",[30,5]],null]],[12],[1,\"\\n\"],[41,[51,[30,6]],[[[41,[48,[30,7]],[[[1,\"          \"],[18,7,null],[1,\"\\n\"]],[]],null]],[]],null],[1,\"  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@name\",\"@description\",\"@required\",\"@defaultValue\",\"@type\",\"@hideControls\",\"&default\"],false,[\"if\",\"concat\",\"unless\",\"has-block\",\"yield\"]]",
    "moduleName": "ember-freestyle/components/freestyle/usage/argument/index.hbs",
    "isStrictMode": false
  });

  class FreestyleUsageArgumentComponent extends _component2.default {
    get shouldRenderDefaultValue() {
      return (0, _utils.isPresent)(this.args.defaultValue);
    }

    get typeLabel() {
      return this.args.typeLabel || this.args.type;
    }

  }

  _exports.default = FreestyleUsageArgumentComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, FreestyleUsageArgumentComponent);
});