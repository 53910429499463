define("ember-keyboard/deprecated/modifiers/keyboard-shortcut", ["exports", "ember-modifier", "@ember/service", "@ember/debug"], function (_exports, _emberModifier, _service, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let Klass;

  if (true) {
    var _class, _descriptor;

    /* This is an element modifier to trigger a click on the element when
     * the specified key is pressed. In the following example, we trigger a
     * click on the button when the B key is pressed:
     *
     * <button {{keyboard-shortcut 'KeyB'}}>Press me or press "B"</button>
     */
    Klass = (_class = class KeyboardShortcutModifier extends _emberModifier.default {
      constructor() {
        super(...arguments);

        _initializerDefineProperty(this, "keyboard", _descriptor, this);

        _defineProperty(this, "keyboardActivated", true);

        _defineProperty(this, "keyboardPriority", 0);

        _defineProperty(this, "keyboardFirstResponder", false);

        _defineProperty(this, "keyboardEventType", 'keypress');
      }

      didReceiveArguments() {
        this.key = this.args.positional[0];

        if ('keyboardActivated' in this.args.named) {
          this.keyboardActivated = this.args.named.keyboardActivated;
        }

        if ('keyboardPriority' in this.args.named) {
          this.keyboardPriority = this.args.named.keyboardPriority;
        }

        if ('keyboardFirstResponder' in this.args.named) {
          this.keyboardFirstResponder = this.args.named.keyboardFirstResponder;
        }

        if ('keyboardEventType' in this.args.named) {
          this.keyboardEventType = this.args.named.keyboardEventType;
        }
      }

      didInstall() {
        (true && !(false) && (0, _debug.deprecate)('The `keyboard-shortcut` modifier of ember-keyboard is deprecated. Please use the `on-key` modifier with no action instead.', false, {
          id: 'ember-keyboard.keyboard-shortcut',
          for: 'ember-keyboard',
          since: '6.0.2',
          until: '7.0.0',
          url: 'https://adopted-ember-addons.github.io/ember-keyboard/deprecations#keyboard-shortcut'
        }));

        try {
          this.keyboard.register(this);
        } catch (e) {
          if (false) {
            console.warn('ember-keyboard\'s modifiers are only supported in Ember 3.8+');
          }

          throw e;
        }
      }

      willRemove() {
        this.keyboard.unregister(this);
      }

      has(triggerName) {
        return triggerName === this.keyboardEventName && this.keyboardActivated;
      }

      trigger(listenerName) {
        if (this.keyboardActivated && listenerName === this.keyboardEventName) {
          this.element.click();
        }
      }

      get keyboardEventName() {
        let {
          key,
          keyboardEventType
        } = this;
        return `${keyboardEventType}:${key}`;
      }

    }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "keyboard", [_service.inject], {
      configurable: true,
      enumerable: true,
      writable: true,
      initializer: null
    })), _class);
  } else {
    Klass = class OnKeyboardModifier extends _emberModifier.default {
      didInstall() {
        throw new Error('ember-keyboard only supports the keyboard-shortcut element modifier in Ember 3.8 and higher.');
      }

    };
  }

  var _default = Klass;
  _exports.default = _default;
});