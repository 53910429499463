define("ember-freestyle/components/freestyle-menu/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/service", "macro-decorators", "@glimmer/component"], function (_exports, _component, _templateFactory, _service, _macroDecorators, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <ul class="FreestyleMenu">
    {{#if this.includeAllOption}}
      <li class="FreestyleMenu-item">
        <LinkTo @query={{hash s=null ss=null f=null}} class="FreestyleMenu-itemLink">
          All
        </LinkTo>
      </li>
    {{/if}}
    {{#each this.menu as |section|}}
      <li class="FreestyleMenu-item">
        <LinkTo @query={{hash f=null s=section.name ss=null}} class="FreestyleMenu-itemLink">
          {{section.name}}
        </LinkTo>
        {{#each section.subsections as |subsection|}}
          <ul class="FreestyleMenu-submenu">
            <li class="FreestyleMenu-submenuItem">
              <LinkTo @query={{hash f=null s=section.name ss=subsection.name}} class="FreestyleMenu-submenuItemLink">
                {{subsection.name}}
              </LinkTo>
            </li>
          </ul>
        {{/each}}
      </li>
    {{/each}}
  </ul>
  
  */
  {
    "id": "Z0aWHAY+",
    "block": "[[[10,\"ul\"],[14,0,\"FreestyleMenu\"],[12],[1,\"\\n\"],[41,[30,0,[\"includeAllOption\"]],[[[1,\"    \"],[10,\"li\"],[14,0,\"FreestyleMenu-item\"],[12],[1,\"\\n      \"],[8,[39,1],[[24,0,\"FreestyleMenu-itemLink\"]],[[\"@query\"],[[28,[37,2],null,[[\"s\",\"ss\",\"f\"],[null,null,null]]]]],[[\"default\"],[[[[1,\"\\n        All\\n      \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[42,[28,[37,4],[[28,[37,4],[[30,0,[\"menu\"]]],null]],null],null,[[[1,\"    \"],[10,\"li\"],[14,0,\"FreestyleMenu-item\"],[12],[1,\"\\n      \"],[8,[39,1],[[24,0,\"FreestyleMenu-itemLink\"]],[[\"@query\"],[[28,[37,2],null,[[\"f\",\"s\",\"ss\"],[null,[30,1,[\"name\"]],null]]]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[30,1,[\"name\"]]],[1,\"\\n      \"]],[]]]]],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,1,[\"subsections\"]]],null]],null],null,[[[1,\"        \"],[10,\"ul\"],[14,0,\"FreestyleMenu-submenu\"],[12],[1,\"\\n          \"],[10,\"li\"],[14,0,\"FreestyleMenu-submenuItem\"],[12],[1,\"\\n            \"],[8,[39,1],[[24,0,\"FreestyleMenu-submenuItemLink\"]],[[\"@query\"],[[28,[37,2],null,[[\"f\",\"s\",\"ss\"],[null,[30,1,[\"name\"]],[30,2,[\"name\"]]]]]]],[[\"default\"],[[[[1,\"\\n              \"],[1,[30,2,[\"name\"]]],[1,\"\\n            \"]],[]]]]],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[2]],null],[1,\"    \"],[13],[1,\"\\n\"]],[1]],null],[13],[1,\"\\n\"]],[\"section\",\"subsection\"],false,[\"if\",\"link-to\",\"hash\",\"each\",\"-track-array\"]]",
    "moduleName": "ember-freestyle/components/freestyle-menu/index.hbs",
    "isStrictMode": false
  });

  let FreestyleMenu = (_dec = (0, _macroDecorators.reads)('args.includeAllOption', true), _dec2 = (0, _macroDecorators.reads)('emberFreestyle.menu'), (_class = class FreestyleMenu extends _component2.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "emberFreestyle", _descriptor, this);

      _initializerDefineProperty(this, "includeAllOption", _descriptor2, this);

      _initializerDefineProperty(this, "menu", _descriptor3, this);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "emberFreestyle", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "includeAllOption", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "menu", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = FreestyleMenu;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, FreestyleMenu);
});