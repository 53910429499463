define("ember-keyboard/utils/trigger-event", ["exports", "ember-keyboard/utils/keyboard-listener"], function (_exports, _keyboardListener) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.triggerKeyUp = _exports.triggerKeyPress = _exports.triggerKeyDown = void 0;

  const triggerKeyEvent = function triggerKeyEvent(eventType, keyCombo, element) {
    let keyboardListener = _keyboardListener.default.parse(`${eventType}:${keyCombo}`);

    const event = keyboardListener.createMatchingKeyboardEvent();
    element.dispatchEvent(event);
  };

  const triggerKeyDown = function triggerKeyDown(keyCombo) {
    let element = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : document;
    triggerKeyEvent('keydown', keyCombo, element);
  };

  _exports.triggerKeyDown = triggerKeyDown;

  const triggerKeyPress = function triggerKeyPress(keyCombo) {
    let element = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : document;
    triggerKeyEvent('keypress', keyCombo, element);
  };

  _exports.triggerKeyPress = triggerKeyPress;

  const triggerKeyUp = function triggerKeyUp(keyCombo) {
    let element = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : document;
    triggerKeyEvent('keyup', keyCombo, element);
  };

  _exports.triggerKeyUp = triggerKeyUp;
});