define("ember-freestyle/components/freestyle/usage/number/control/index", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@ember/utils"], function (_exports, _component, _templateFactory, _component2, _object, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{! template-lint-disable require-input-label }}
  
  {{#if this.shouldRenderRangeInput}}
    <input
      type="range"
      value={{@value}}
      min={{@min}}
      max={{@max}}
      step={{if @step @step 1}}
      {{on 'input' this.onInput}}
    />
    {{@value}}
  {{else}}
    <input
      type="number"
      value={{@value}}
      step={{if @step @step 1}}
      {{on 'input' this.onInput}}
    />
  {{/if}}
  
  */
  {
    "id": "U3+2SzA7",
    "block": "[[[1,\"\\n\"],[41,[30,0,[\"shouldRenderRangeInput\"]],[[[1,\"  \"],[11,\"input\"],[16,2,[30,1]],[16,\"min\",[30,2]],[16,\"max\",[30,3]],[16,\"step\",[52,[30,4],[30,4],1]],[24,4,\"range\"],[4,[38,1],[\"input\",[30,0,[\"onInput\"]]],null],[12],[13],[1,\"\\n  \"],[1,[30,1]],[1,\"\\n\"]],[]],[[[1,\"  \"],[11,\"input\"],[16,2,[30,1]],[16,\"step\",[52,[30,4],[30,4],1]],[24,4,\"number\"],[4,[38,1],[\"input\",[30,0,[\"onInput\"]]],null],[12],[13],[1,\"\\n\"]],[]]]],[\"@value\",\"@min\",\"@max\",\"@step\"],false,[\"if\",\"on\"]]",
    "moduleName": "ember-freestyle/components/freestyle/usage/number/control/index.hbs",
    "isStrictMode": false
  });

  let FreestyleUsageNumberComponent = (_class = class FreestyleUsageNumberComponent extends _component2.default {
    get shouldRenderRangeInput() {
      return (0, _utils.isPresent)(this.args.min) && (0, _utils.isPresent)(this.args.max);
    }

    onInput(event) {
      let {
        value
      } = event.target;
      this.args.onInput(value ? parseFloat(value) : null);
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "onInput", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onInput"), _class.prototype)), _class);
  _exports.default = FreestyleUsageNumberComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, FreestyleUsageNumberComponent);
});