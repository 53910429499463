define("ember-keyboard/mixins/keyboard-first-responder-on-focus", ["exports", "@ember/object/mixin", "@ember/object/evented", "@ember/object", "@ember/debug"], function (_exports, _mixin, _evented, _object, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable ember/no-new-mixins */
  var _default = _mixin.default.create({
    init() {
      (true && !(false) && (0, _debug.deprecate)('`EKFirstResponderOnFocusMixin` of ember-keyboard is deprecated. This behavior is now the default when using the `on-key` modifier with a text field. For other use cases, implement manually.', false, {
        id: 'ember-keyboard.keyboard-first-responder-on-focus-mixin',
        for: 'ember-keyboard',
        since: '6.0.2',
        until: '7.0.0',
        url: 'https://adopted-ember-addons.github.io/ember-keyboard/deprecations#keyboard-first-responder-on-focus-mixin'
      }));
      return this._super(...arguments);
    },

    makeFirstResponderOnFocusIn: (0, _evented.on)('click', 'focusIn', function () {
      (0, _object.setProperties)(this, {
        keyboardActivated: true,
        keyboardFirstResponder: true
      });
    }),
    resignFirstResponderOnFocusOut: (0, _evented.on)('focusOut', function () {
      (0, _object.set)(this, 'keyboardFirstResponder', false);
    })
  });

  _exports.default = _default;
});