define("style-guide/components/style-guide/pagination/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <@Subsection @name="Pagination">
    <FreestyleCollection as |collection|>
      <collection.variant @key="default">
        <Freestyle::Usage @name="Default">
          <:example>
            <div class="bg-gray-100 p-2">
              <Pagination
                @currentPage={{@page}}
                @itemsPerPage={{10}}
                @paramName="page"
                @totalItems={{100}}
              />
            </div>
          </:example>
        </Freestyle::Usage>
      </collection.variant>
    </FreestyleCollection>
  </@Subsection>
  */
  {
    "id": "TrG18l79",
    "block": "[[[8,[30,1],null,[[\"@name\"],[\"Pagination\"]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[30,2,[\"variant\"]],null,[[\"@key\"],[\"default\"]],[[\"default\"],[[[[1,\"\\n      \"],[8,[39,1],null,[[\"@name\",\"@source\"],[\"Default\",\"       \\n<div class=\\\"bg-gray-100 p-2\\\">\\n  <Pagination\\n    @currentPage={{@page}}\\n    @itemsPerPage={{10}}\\n    @paramName=\\\"page\\\"\\n    @totalItems={{100}}\\n  />\\n</div>\\n        \"]],[[\"example\"],[[[[1,\"\\n          \"],[10,0],[14,0,\"bg-gray-100 p-2\"],[12],[1,\"\\n            \"],[8,[39,2],null,[[\"@currentPage\",\"@itemsPerPage\",\"@paramName\",\"@totalItems\"],[[30,3],10,\"page\",100]],null],[1,\"\\n          \"],[13],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[2]]]]],[1,\"\\n\"]],[]]]]]],[\"@Subsection\",\"collection\",\"@page\"],false,[\"freestyle-collection\",\"freestyle/usage\",\"pagination\"]]",
    "moduleName": "style-guide/components/style-guide/pagination/index.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});