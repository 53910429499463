define("ember-freestyle/components/freestyle-subsection/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/service", "@glimmer/component"], function (_exports, _component, _templateFactory, _service, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="FreestyleSubsection {{if this.show 'is-showing' 'is-hidden'}}"
    {{did-insert (fn this.emberFreestyle.registerSection @section @name)}}
    ...attributes
  >
    {{#if this.show}}
      {{#if @name}}
        <div class="FreestyleSubsection-name">
          {{@name}}
        </div>
      {{/if}}
      {{yield}}
    {{/if}}
  </div>
  
  */
  {
    "id": "r2wBcaAz",
    "block": "[[[11,0],[16,0,[29,[\"FreestyleSubsection \",[52,[30,0,[\"show\"]],\"is-showing\",\"is-hidden\"]]]],[17,1],[4,[38,1],[[28,[37,2],[[30,0,[\"emberFreestyle\",\"registerSection\"]],[30,2],[30,3]],null]],null],[12],[1,\"\\n\"],[41,[30,0,[\"show\"]],[[[41,[30,3],[[[1,\"      \"],[10,0],[14,0,\"FreestyleSubsection-name\"],[12],[1,\"\\n        \"],[1,[30,3]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[18,4,null],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"]],[\"&attrs\",\"@section\",\"@name\",\"&default\"],false,[\"if\",\"did-insert\",\"fn\",\"yield\"]]",
    "moduleName": "ember-freestyle/components/freestyle-subsection/index.hbs",
    "isStrictMode": false
  });

  let FreestyleSubsection = (_class = class FreestyleSubsection extends _component2.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "emberFreestyle", _descriptor, this);
    }

    get show() {
      return this.emberFreestyle.shouldShowSubsection(this.args.section, this.args.name);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "emberFreestyle", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = FreestyleSubsection;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, FreestyleSubsection);
});