define("ember-freestyle/components/freestyle/usage/number/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Freestyle::Usage::Argument
    @type="Number"
    @name={{@name}}
    @description={{@description}}
    @required={{@required}}
    @defaultValue={{@defaultValue}}
  >
    <Freestyle::Usage::Number::Control
      @value={{@value}}
      @min={{@min}}
      @max={{@max}}
      @step={{@step}}
      @onInput={{@onInput}}
    />
  </Freestyle::Usage::Argument>
  */
  {
    "id": "OAI7IJjA",
    "block": "[[[8,[39,0],null,[[\"@type\",\"@name\",\"@description\",\"@required\",\"@defaultValue\"],[\"Number\",[30,1],[30,2],[30,3],[30,4]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,1],null,[[\"@value\",\"@min\",\"@max\",\"@step\",\"@onInput\"],[[30,5],[30,6],[30,7],[30,8],[30,9]]],null],[1,\"\\n\"]],[]]]]]],[\"@name\",\"@description\",\"@required\",\"@defaultValue\",\"@value\",\"@min\",\"@max\",\"@step\",\"@onInput\"],false,[\"freestyle/usage/argument\",\"freestyle/usage/number/control\"]]",
    "moduleName": "ember-freestyle/components/freestyle/usage/number/index.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});