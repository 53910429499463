define("ember-freestyle/components/freestyle-palette-item/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/template", "@ember/object"], function (_exports, _component, _templateFactory, _template, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class='FreestylePaletteItem'>
    <div class='FreestylePaletteItem-color' style={{this.paletteStyle}}></div>
    <div class='FreestylePaletteItem-info'>
      <p class='FreestylePaletteItem-hex'>{{@color.base}}</p>
      <p class='FreestylePaletteItem-name'>{{@color.name}}</p>
    </div>
  </div>
  */
  {
    "id": "dCYZdfnE",
    "block": "[[[10,0],[14,0,\"FreestylePaletteItem\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"FreestylePaletteItem-color\"],[15,5,[30,0,[\"paletteStyle\"]]],[12],[13],[1,\"\\n  \"],[10,0],[14,0,\"FreestylePaletteItem-info\"],[12],[1,\"\\n    \"],[10,2],[14,0,\"FreestylePaletteItem-hex\"],[12],[1,[30,1,[\"base\"]]],[13],[1,\"\\n    \"],[10,2],[14,0,\"FreestylePaletteItem-name\"],[12],[1,[30,1,[\"name\"]]],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@color\"],false,[]]",
    "moduleName": "ember-freestyle/components/freestyle-palette-item/index.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend({
    tagName: '',
    // color - passed in
    paletteStyle: (0, _object.computed)('color', function () {
      let color = this.color;
      return (0, _template.htmlSafe)(`background-color: ${color.base};`);
    })
  }));

  _exports.default = _default;
});